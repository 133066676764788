import { Article, ArticleRequestDTO, MessageToClient } from "../dto/Dtos";
import { InternalError, UnAuthorizedError } from "../dto/CustomErrors";
const { REACT_APP_API_HOST } = process.env;

export async function fetchArticles(): Promise<Article[]> {
  const res = await fetch(REACT_APP_API_HOST + "/api/admin/v1/articles", {
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  });
  return returnResponseElseError(res, fetchArticles);
}

export async function fetchArticleRequests(): Promise<ArticleRequestDTO[]> {
  const res = await fetch(REACT_APP_API_HOST + "/api/admin/v1/getAllRequests", {
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  });
  return returnResponseElseError(res, fetchArticleRequests);
}

export async function fetchRequest(
  id: number
): Promise<ArticleRequestDTO | null> {
  const res = await fetch(
    REACT_APP_API_HOST + "/api/admin/v1/getRequest/" + id,
    {
      credentials: "same-origin",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    }
  );
  return returnResponseElseError(res, fetchRequest);
}

export function openedRequest(id: number): void {
  fetch(REACT_APP_API_HOST + "/api/admin/v1/articleRequestOpened/" + id, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  });
}

export async function removeRequest(id: number): Promise<void> {
  await fetch(REACT_APP_API_HOST + "/api/admin/v1/removeRequest/" + id, {
    method: "DELETE",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  });
}

export async function sendParcelInfo(
  id: number,
  parcelNumber: string
): Promise<void> {
  await fetch(
    REACT_APP_API_HOST + "/api/admin/v1/parcel/" + id + "/" + parcelNumber,
    {
      method: "POST",
      credentials: "same-origin",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    }
  );
}

export async function sendMessage(message: MessageToClient): Promise<void> {
  const requestHeaders: HeadersInit = new Headers();

  requestHeaders.set(
    "Authorization",
    "Bearer " + localStorage.getItem("access_token")
  );
  requestHeaders.set("Content-Type", "application/json");
  await fetch(REACT_APP_API_HOST + "/api/admin/v1/message", {
    method: "POST",
    credentials: "same-origin",
    headers: requestHeaders,
    body: JSON.stringify(message),
  });
}

export async function saveItem(updatedItem: Article): Promise<Response> {
  const requestHeaders: HeadersInit = new Headers();

  requestHeaders.set(
    "Authorization",
    "Bearer " + localStorage.getItem("access_token")
  );
  requestHeaders.set("Content-Type", "application/json");
  return fetch(REACT_APP_API_HOST + "/api/admin/v1/article", {
    method: "POST",
    credentials: "same-origin",
    headers: requestHeaders,
    body: JSON.stringify(updatedItem),
  });
}

export async function deleteImages() {
  const res = await fetch(REACT_APP_API_HOST + "/api/admin/v1/images", {
    method: "DELETE",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  });
  return returnResponseElseError(res, deleteImages);
}

export async function deleteArticle(selectedItems: number[]) {
  var url = new URL(REACT_APP_API_HOST + "/api/admin/v2/article/");
  var params = { ids: selectedItems };
  //@ts-expect-error
  url.search = new URLSearchParams(params).toString();

  const res = await fetch(url, {
    method: "DELETE",
    credentials: "same-origin",
    headers: {
      Authorization: "Bearer " + localStorage.getItem("access_token"),
    },
  });
  return returnResponseElseError(res, deleteArticle);
}

async function returnResponseElseError(response: Response, callback: Function) {
  if (response.status === 403) {
    return resetToken(callback);
  } else if (!response.ok) {
    throw new InternalError("Internal error occured!");
  }
  return await response.json();
}

export async function resetToken(callback: Function) {
  const response = await fetch(
    REACT_APP_API_HOST + "/api/v1/security/token/refresh",
    {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("refresh_token"),
      },
    }
  );

  if (!response.ok) {
    throw new UnAuthorizedError("Unauthorized user!");
  }
  const token = await response.json();
  localStorage.setItem("access_token", token.access_token);
  sessionStorage.setItem("refresh_token", token.refresh_token);
  console.log("Resetted token");
  return callback();
}
