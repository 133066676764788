import React from "react";
import styled from "styled-components";

const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <ContentContainer lightBg={true} id="Support">
        <ContentWrapper>
          <ContentRow imgStart={false}>
            <TextWrapper>
              <Heading lightText={false}>Privacy Policy</Heading>
            </TextWrapper>
            <SupportColumn>
              1. We do not share any information with third party vendors.
            </SupportColumn>
            <SupportColumn>
              2. The only information that is stored is the details for selling
              and shipping.
            </SupportColumn>
            <SupportColumn>
              3. We do not store or track any information of your activity
            </SupportColumn>
          </ContentRow>
        </ContentWrapper>
      </ContentContainer>
    </>
  );
};

export default PrivacyPolicy;

export const ContentContainer = styled.div`
  color: #fff;
  margin-top: 200px;
  height: 70vh;

  background: ${({ primaryBg }) =>
    primaryBg ? "var(--main-bg-color)" : "var(--secondary-bg-color)"};

  @media screen and (max-width: 768px) {
    height: 100vh;
    padding: 100px 0;
    margin-top: 0px;
  }
`;

export const ContentWrapper = styled.div`
  display: grid;
  z-index: 1;
  width: 100%;
  height: fit-content;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }
`;

export const ContentRow = styled.div`
  display: grid;
  margin-bottom: 100px;
  width: 100%;
  align-items: center;
`;

export const TextWrapper = styled.div`
  width: 100%;
  align-items: center;
  text-align: center;
  padding-top: 0;
`;

export const Heading = styled.h1`
  margin-bottom: 14px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) =>
    lightText ? "var(--main-text-color)" : "var(--secondary-text-color)"};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) =>
    darkText ? "var(--secondary-text-color)" : "var(--main-text-color)"};
`;

export const TextArea = styled.p`
  width: 100%;
  margin-bottom: 35px;
  font-size: 15px;
  color: ${({ darkText }) =>
    darkText ? "var(--secondary-text-color)" : "var(--main-text-color)"};
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

export const ImgWrap = styled.div`
  width: 100%;
  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;

export const Img = styled.img`
  width: 800px;
  margin: 0 0 10px 0;
  padding-right: 0;
  @media screen and (max-width: 768px) {
    height: 150px;
    width: 400px;
  }
`;

export const ImgSmall = styled.img`
  width: 200px;
  margin: 0 0 10px 0;
  padding-right: 0;
  @media screen and (max-width: 768px) {
    height: 200px;
    width: 200px;
  }
`;

export const SecondTitle = styled.div`
  color: black;
  display: block;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  font-size: 2rem;
  display: grid;
  align-items: center;
`;

export const SupportColumn = styled.div`
  margin-top: 15px;
  font-size: 1.2rem;
  color: black;

  @media screen and (max-width: 768px) {
    margin-top: 15px;
    width: 100%;
  }
`;
