import React from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { MdArrowLeft, MdArrowRight } from "react-icons/md";

import ShopNav from "./ShopNav";
import { useParams } from "react-router-dom";
import {
  fetchArticle,
  fetchArticles,
  fetchIpInfo,
} from "../../controller/ShopController";
import { Article, IpInfo, ShopView } from "../../dto/Dtos";
import { getPriceFromCountryAsText } from "./CartActions";
import Itempreview from "./Itempreview";
const { REACT_APP_API_HOST } = process.env;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Shop = () => {
  const [selectedItem, setSelectedItem] = useState<Article>();
  const [articles, setArticles] = useState<Article[]>([]);
  const [searchText, setSearchText] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [ipInfo, setIpInfo] = useState<IpInfo>();
  const [view, setView] = useState<ShopView>(ShopView.Main);
  const [maxPages, setMaxPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  let { item_id } = useParams();

  //REQUEST-START

  //REQUEST-END

  const handleResize = () => {
    if (getWindowDimensions().width < 768) {
      setImageUrl(REACT_APP_API_HOST + "/api/v2/article/image/low/");
    } else if (getWindowDimensions().width < 1100) {
      setImageUrl(REACT_APP_API_HOST + "/api/v2/article/image/med/");
    } else {
      setImageUrl(REACT_APP_API_HOST + "/api/v2/article/image/med/");
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const getIpInfo = async () => {
      const ipInfo = await fetchIpInfo();
      setIpInfo(ipInfo);
    };
    getIpInfo();
  }, []);

  useEffect(() => {
    const getArticles = async () => {
      const wrapper = await fetchArticles(0, searchText);
      setMaxPages(wrapper.pages);
      setArticles(wrapper.articles);
    };

    getArticles();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const openItem = (article: Article) => {
    setSelectedItem(article);
    setView(ShopView.Preview);
  };

  const handleQueryParams = React.useCallback((id: number) => {
    fetchArticle(id).then((response) => {
      setSelectedItem(response);
      setView(ShopView.Preview);
      window.history.replaceState(null, "", "/shop");
    });
  }, []);

  useEffect(() => {
    fetchArticles(currentPage, searchText).then((wrapper) => {
      setMaxPages(wrapper.pages);
      setArticles(wrapper.articles);
    });
    window.scrollTo({ top: 0, behavior: "smooth" });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  function changePage(direction: number) {
    if (currentPage + direction < 0 || currentPage + direction > maxPages) {
      return;
    }

    if (direction > 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    setCurrentPage(currentPage + direction);
  }

  function changeView(): React.JSX.Element {
    if (view === ShopView.Main) {
      return (
        <ShopContainer>
          <ShopNav setSearchText={setSearchText} />

          <ShopWrapper>
            {articles
              .filter((article) => !article.hide)
              .map((article: Article) => (
                <ShopCard onClick={() => openItem(article)} key={article.id}>
                  {article.inStock === 0 ? <SoldOut>Sold out</SoldOut> : null}
                  <ItemImage src={imageUrl + article.imagePath}></ItemImage>
                  <ItemDescription>{article.name}</ItemDescription>
                  <ItemPrice>
                    {ipInfo
                      ? getPriceFromCountryAsText(article, ipInfo.country_name)
                      : "0 KR"}
                  </ItemPrice>
                </ShopCard>
              ))}
          </ShopWrapper>
          <PagesContainer>
            <LeftArrow size={45} onClick={() => changePage(-1)} />
            <PagesArea>
              {"Page " + (currentPage + 1) + " of " + (maxPages + 1)}
            </PagesArea>
            <RightArrow size={45} onClick={() => changePage(1)} />
          </PagesContainer>
        </ShopContainer>
      );
    } else if (view === ShopView.Preview) {
      return (
        <Itempreview
          selectedItem={selectedItem}
          ipInfo={ipInfo}
          setView={setView}
        />
      );
    } else {
      return (
        <ShopContainer>
          <ShopNav setSearchText={setSearchText} />

          <ShopWrapper>
            {articles
              .filter((article) => !article.hide)
              .map((article: Article) => (
                <ShopCard onClick={() => openItem(article)} key={article.id}>
                  {article.inStock === 0 ? <SoldOut>Sold out</SoldOut> : null}
                  <ItemImage src={imageUrl + article.imagePath}></ItemImage>
                  <ItemDescription>{article.name}</ItemDescription>
                  <ItemPrice>
                    {ipInfo
                      ? getPriceFromCountryAsText(article, ipInfo.country_name)
                      : "0 KR"}
                  </ItemPrice>
                </ShopCard>
              ))}
          </ShopWrapper>
          <PagesContainer>
            <LeftArrow size={45} onClick={() => changePage(-1)} />
            <PagesArea>
              {"Page " + (currentPage + 1) + " of " + (maxPages + 1)}
            </PagesArea>
            <RightArrow size={45} onClick={() => changePage(1)} />
          </PagesContainer>
        </ShopContainer>
      );
    }
  }

  useEffect(() => {
    if (item_id != null) {
      handleQueryParams(parseInt(item_id));
    }
  }, [item_id, handleQueryParams]);

  return <>{changeView()}</>;
};

export default Shop;

const ShopContainer = styled.div`
  margin-top: 80px;
  padding-top: 30px;
  padding-bottom: 10px;

  background: var(--shop-bg-color);
`;

const ShopWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  padding: 0 200px;
  grid-gap: 16px;
  min-height: 800px;

  @media screen and (max-width: 1300px) {
    grid-gap: 8px;
    padding: 0 20px;
    min-height: 0;

    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 768px) {
    grid-gap: 8px;
    padding: 0 20px;
    min-height: 0;

    grid-template-columns: 1fr;
  }
`;

const ShopCard = styled.div`
  align-items: center;
  text-align: center;
  padding: 20px 10px 20px 10px;
  margin-bottom: 20px;
  transition: all 0.2s ease-in-out;

  &:hover {
    @media screen and (min-width: 1300px) {
      transform: scale(1.02);
      transition: all 0.2s ease-in-out;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1300px) {
    padding: 10px 5px;
    margin-bottom: 0px;
    height: 100%;
  }
`;

const ItemImage = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  margin-bottom: 20px;

  @media screen and (max-width: 1100px) {
    width: 100%;
    height: 450px;
  }

  @media screen and (max-width: 480px) {
    width: 80%;
    height: 80%;
  }
`;

const LeftArrow = styled(MdArrowLeft)`
  cursor: pointer;
`;

const RightArrow = styled(MdArrowRight)`
  cursor: pointer;
`;

const ItemDescription = styled.h3`
  display: block;
  font-size: 1rem;
  margin-bottom: 5px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  @media screen and (max-width: 480px) {
    font-size: 1.1rem;
  }
`;

const SoldOut = styled.div`
  color: red;
  padding: 20px;
  font-weight: 800;
  background-color: #00000063;
  display: flex;
  position: absolute;
`;

const ItemPrice = styled.h4`
  display: block;
  font-size: 1rem;
  text-align: center;
  font-family: "Poppins", sans-serif;
  @media screen and (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const PagesContainer = styled.ul`
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const PagesArea = styled.ul`
  padding: 13px 0;
`;
