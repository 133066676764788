//import { useState, useEffect } from "react";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import "./css/App.css";
import Home from "./pages";
import ShopPage from "./pages/shop";
import Login from "./pages/login";
import AdminProducts from "./components/AdminSection/AdminProducts";
import ContactPage from "./pages/contact";
import SupportPage from "./pages/support";
import TermsPage from "./pages/terms";
import PrivacyPage from "./pages/privacy";
import DownloadsPage from "./pages/downloads";
import AdminMain from "./components/AdminSection/AdminMain";
import Requests from "./components/AdminSection/Requests";
import CartPage from "./pages/cart";

const { REACT_APP_API_HOST } = process.env;

function App() {
  useEffect(() => {
    const addCount = async () => {
      const res = await fetch(`${REACT_APP_API_HOST}/api/v2/article/count`);
      const data = await res.json();
      return data;
    };

    addCount();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path="/shop" element={<ShopPage />} />
        <Route path="/shop/:item_id" element={<ShopPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<AdminMain />} />
        <Route path="/admin/requests/:request_id" element={<Requests />} />
        <Route path="/admin/products" element={<AdminProducts />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/cart" element={<CartPage />} />

        <Route path="/downloads" element={<DownloadsPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
