export const paragraph1 = {
  id: "contact",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  headline: "CHITRAM TV BOX",
  bottomLine: "For the nordic countries",
  description: "WATCH CHITRAMTV ON YOUR TV, COMPUTER, TABLET & MOBILE",
  description2:
    "One Subscription is enough to watch Chitram TV on your TV/ PC/ Tablet/ Mobile, but to watch ChitramTV on your TV you need an Android based TV like Sony, TCL, Mi ect. or a ChitramTV Set-Top-Box",
  buttonLabel: "Order now",
  imgStart: true,
  images: [
    "/images/20220215_2317092.jpg",
    "/images/2.jpg",
    "/images/ChitramTVSoccerAd.jpg",
    "/images/ChitramTVOceanAd.jpg",
  ],
  alt: "Not available",
  primaryBg: false,
  primary: true,
  darkText: false,
};

export const paragraph2 = {
  id: "contact",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  bottomLine: "HUNDREDS OF YOUR MOST FAVOURITE CHANNELS IN HD",
  description:
    "Chitram TV has gained their trust among the viewers and emerged as a reliable name in the television business. Chitram offers a premium collection of Indian TV channels in more than 10 languages, and their Classic  boxes now allow the viewers to enjoy the best quality high-definition pictures, HQ sound and a lot of features at a discounted price.Chitram TV offers more than 350 Indian TV channels across the globe. Now viewers can enjoy their favourite television channels with Chitram subscription and enjoy High Definition experience without any mandated satellite dish and cable connection. We provide a great opportunity to our viewers to enjoy their favourite television programmes in HD with Chitram online renewal.Chitram TV service is compatible with different platforms, and all these products are connected in cluster architecture to build a scalable solution. ChitramTV Box & Renewal for Sweden, Norway, Finland and Denmark",
  description2:
    "Compatible with any  TV( SmartTV or Non-SmartTV) and any model. Required a HDMI Port only. Dual OS , 4 x Fater Quad Core Processor , Quad Core Processor, Dual WiFi ( 2.4 Ghz & 5 Ghz ) and Dolby Digital DTS ATMOS Sound . ChitramTV Classic box with very easy to use Interface and simple & Slim Dune HD IR Remote  with easy Channel Zapping. ChitramTV Box is highly recommended for your family.",
  buttonLabel: "",
  imgStart: true,
  img: "/images/frontimagesnew.webp",
  alt: "Not available",
  primaryBg: false,
  primary: true,
  darkText: false,
};

export const features = [
  {
    img: "/images/play-circle-svgrepo-com.svg",
    title: "CatchUp TV",
    text: "14 Days after live broadcasting, you can still watch your missed favourite TV Shows & Serials with the CatchUp function.",
  },
  {
    img: "/images/movies-svgrepo-com.svg",
    title: "Movies",
    text: "A constantly updated and free collection of exciting movies sorted by genre. New movies are updated weekly – from classical and evergreens to the newest Blockbusters.",
  },
  {
    img: "/images/clock-two-svgrepo-com.svg",
    title: "Timeshift",
    text: "Timeshift allows you as a subscriber, living in different time zones, to customize and watch TV shows at your convenience",
  },
];
