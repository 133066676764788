import { ContactRequestClient } from "../dto/Dtos";

const { REACT_APP_API_HOST } = process.env;

export async function postContactRequest(
  contactRequest: ContactRequestClient,
  recaptchaToken: string
) {
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("Accept", "application/json");
  requestHeaders.set("Content-Type", "application/json");
  requestHeaders.set("Recaptcha-Token", recaptchaToken);
  return fetch(REACT_APP_API_HOST + "/api/v1/contact/request", {
    method: "POST",
    headers: requestHeaders,
    body: JSON.stringify(contactRequest),
  });
}
