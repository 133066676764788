import React from "react";
import {
  SidebarContainer,
  Icon,
  SearchIcon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLinkR,
} from "./SidebarElements";
import { NavbarItem } from "../../NavbarContent";

interface NavbarProps {
  isOpen: any; // Assuming Person is an interface or type that you have defined
  toggle: any;
  navbarItems: NavbarItem[];
}

const Sidebar: React.FC<NavbarProps> = ({ isOpen, toggle, navbarItems }) => {
  var animationTimer = 0;

  return (
    <>
      <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <SearchIcon onClick={toggle} />
        <Icon>
          <CloseIcon />
        </Icon>
        <SidebarWrapper>
          <SidebarMenu>
            {navbarItems.map((item, index: number) => (
              <SidebarLinkR
                key={index}
                $animationTimer={animationTimer++}
                $isOpen={isOpen}
                to={item.url}
                onClick={toggle}
              >
                {item.text}
              </SidebarLinkR>
            ))}
          </SidebarMenu>
        </SidebarWrapper>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
