import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { Article, CartView } from "../../dto/Dtos";
import { useNavigate } from "react-router-dom";
import { SwimLane } from "./ShoppingCart";
import { clearCart } from "./CartActions";

interface ConfirmationProps {
  cartItems: Article[];
  response: Response | undefined;
  setView: Dispatch<SetStateAction<CartView>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setlaneState: Dispatch<SetStateAction<SwimLane>>;
}

const Confirmation: React.FC<ConfirmationProps> = ({
  cartItems,
  response,
  setView,
  setLoading,
  setlaneState,
}) => {
  let navigate = useNavigate();
  const getErrorMessage = (
    response: Response | undefined,
    cartItems: Article[]
  ): React.JSX.Element => {
    if (response && response.status === 406) {
      return (
        <ItemContent>
          <h2>Invalid recaptcha!</h2>
          <h3>Please try again.</h3>
          <OkButton
            onClick={() => {
              setLoading(false);
              setlaneState(SwimLane.Adress);
              setView(CartView.Cart);
            }}
          >
            Try again
          </OkButton>
        </ItemContent>
      );
    } else if (response && response.status !== 200) {
      return (
        <ItemContent>
          <h2>An error has occured!</h2>
          <h3> Your request could not be processed, please try again.</h3>
          <p>Probably article is sold out.</p>
          <OkButton
            onClick={() => {
              setLoading(false);
              setlaneState(SwimLane.Adress);
              setView(CartView.Cart);
            }}
          >
            Try again
          </OkButton>
        </ItemContent>
      );
    } else {
      return (
        <ItemContent>
          <h2>Thank you, your request has been recieved!</h2>
          <h3> We will get in touch with you as soon as possible.</h3>
          <OkButton
            onClick={() => {
              setLoading(false);
              clearCart();
              navigate("/shop");
            }}
          >
            Continue shopping
          </OkButton>
        </ItemContent>
      );
    }
  };
  return (
    <>
      <ItemWrapper>{getErrorMessage(response, cartItems)}</ItemWrapper>
    </>
  );
};

export default Confirmation;

const OkButton = styled.button`
  padding: 10px 24px;
  color: #fff;
  border: none;
  background: #141414;
`;

const ItemWrapper = styled.div`
  width: 500px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  z-index: 100;
  border-radius: 10px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  h4 {
    margin-top: 20px;
  }

  @media screen and (max-width: 768px) {
    width: 350px;
    height: 400px;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 0.4fr 1fr;
  }
`;

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  line-height: 1.8;
  color: #141414;
  padding: 20px 10px;

  p {
    margin-bottom: 1rem;
  }
`;
