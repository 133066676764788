import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { navbarItems } from "../NavbarContent";
import PrivacyPolicy from "../components/TermsAndPrivacySection/PrivacyPolicy";
const PrivacyPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} navbarItems={navbarItems} />
      <Navbar toggle={toggle} setScroll={false} navbarItems={navbarItems} />
      <PrivacyPolicy />
    </>
  );
};

export default PrivacyPage;
