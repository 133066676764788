import React from "react";
import "react-slideshow-image/dist/styles.css";
import styled from "styled-components";
import { downloadFile } from "../../controller/DownloadController";

const DownloadSection: React.FC = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <>
      <ContentContainer lightBg={true} id="Support">
        <ContentWrapper>
          <ContentRow imgStart={false}>
            <TextWrapper>
              <Heading lightText={false}>Downloads</Heading>
              <TextArea darkText={true}>
                Available for android devices, laptops with windows and
                Firestick devices
              </TextArea>
            </TextWrapper>
            <DownloadCardContainer>
              <DownloadCard>
                Available for Android Devices and Android Smart Tv
                <ImgWrap>
                  <Img src="/images/338-3386934_another-influencing-factor-is-actually-regulation-associated-mobiles_1.webp" />
                </ImgWrap>
                <TextArea>
                  ChitramTV Supports in Android TV, Android mobiles and Tablets.
                  Click the Download Buttons and Installing it in your Devices
                  requires Android 2.3 or Later Versions.
                </TextArea>
                <DownloadButton
                  onClick={() => {
                    downloadFile("ctv-m.apk");
                  }}
                >
                  Download
                </DownloadButton>
              </DownloadCard>
              <DownloadCard>
                Available for Laptop Devices
                <ImgWrap>
                  <Img src="/images/kindpng_1997004.webp" />
                </ImgWrap>
                <TextArea>
                  ChitramTV Supports for Windows OS. Click the Download Buttons
                  and Download LD Player Software and Installing it in your
                  Laptops. Then Download Chitramtv APK and Install on LD player.
                </TextArea>
                <DownloadButton
                  onClick={() => {
                    downloadFile("LDPlayer9_ens_1379_ld.exe");
                  }}
                >
                  Download
                </DownloadButton>
              </DownloadCard>
              <DownloadCard>
                Available for FireStick & Firestick 4K
                <ImgWrap>
                  <Img src="/images/602-6027414_amazon-fire-tv-stick-4k-amazon-fire-tv.webp" />
                </ImgWrap>
                <TextArea>
                  <ListContainer>
                    <li>
                      Enable “Apps from Unknown Sources” in Amazon Fire Stick
                      (Go to settings-&gt;Device-&gt;Developer Options-&gt;Apps
                      from Unknown Sources-&gt;Select “ON”).
                    </li>
                    <li>
                      Install the Downloader app from Fire TV store. (Go to
                      search – Type”Downloader”).
                    </li>
                    <li>
                      Once installed, open the Downloader App and Download APK
                      File.
                    </li>
                    <li>Install the APK and Login in your account.</li>
                  </ListContainer>
                </TextArea>
                <DownloadButton
                  onClick={() => {
                    downloadFile("ctv-tv.apk");
                  }}
                >
                  Download
                </DownloadButton>
              </DownloadCard>
            </DownloadCardContainer>
          </ContentRow>
        </ContentWrapper>
      </ContentContainer>
    </>
  );
};

export default DownloadSection;

const ContentContainer = styled.div`
  color: #fff;
  margin-top: 200px;

  background: ${({ primaryBg }) =>
    primaryBg ? "var(--main-bg-color)" : "var(--secondary-bg-color)"};

  @media screen and (max-width: 768px) {
    padding: 100px 0;
    margin-top: 0px;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  z-index: 1;
  width: 100%;
  height: fit-content;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }
`;

const ContentRow = styled.div`
  display: grid;
  margin-bottom: 100px;
  width: 100%;
  align-items: center;
`;

const TextWrapper = styled.div`
  width: 100%;
  align-items: center;
  text-align: center;
  padding-top: 0;
`;

const Heading = styled.h1`
  margin-bottom: 14px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) =>
    lightText ? "var(--main-text-color)" : "var(--secondary-text-color)"};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

const TextArea = styled.p`
  width: 100%;
  margin-bottom: 35px;
  font-size: 18px;
  color: ${({ darkText }) =>
    darkText ? "var(--secondary-text-color)" : "var(--main-text-color)"};
`;

const ImgWrap = styled.div`
  width: 100px;
  margin-top: 15px;
  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;

const Img = styled.img`
  margin: 0 0 10px 0;
  padding-right: 0;
  @media screen and (max-width: 768px) {
    height: 250px;
  }
`;

const DownloadCardContainer = styled.div`
  color: black;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  font-size: 2rem;
  grid-column-gap: 50px;
  align-items: center;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
`;

const DownloadCard = styled.div`
  width: 100%;
  height: 900px;
  padding: 10px;
  border-radius: 20px;
  display: grid;

  background-color: white;
  @media screen and (max-width: 768px) {
    height: fit-content;
    text-align: center;
  }
`;

const ListContainer = styled.ol`
  width: 100%;
  padding: 20px;
`;

const DownloadButton = styled.button`
  height: 50px;
  width: 200px;
  justify-self: center;

  margin-bottom: 10px;
  align-self: flex-end;
  padding: 10px 24px;
  cursor: pointer;
  color: #fff;
  margin-top: 20px;
  border-radius: 15px;
  border: none;
  background: var(--button-primary-bgcolor);
`;
