import React from "react";
import "react-slideshow-image/dist/styles.css";
import {
  ContentContainer,
  ContentWrapper,
  ContentRow,
  TextWrapper,
  Heading,
  ImgWrap,
  Img,
  TextArea,
  SecondTitle,
  SupportGrid,
  SupportColumn,
  ImgSmall,
} from "./SupportStyles";
const SupportSection: React.FC = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <>
      <ContentContainer lightBg={true} id="Support">
        <ContentWrapper>
          <ContentRow imgStart={false}>
            <TextWrapper>
              <Heading lightText={false}>ChitramTV Setup Guide</Heading>
              <TextArea darkText={true}>
                ChitramTV is an internet based Indian TV service in the UK. We
                provide the best 4K TV Set top Box which works flawlessly
                through the internet connection, simple as that. All you need is
                a broadband internet connection of 10 Mbit/s. ChitramTV can be
                watched in two simple ways .
              </TextArea>
            </TextWrapper>
            <SecondTitle>How to connect ChitramTV Box to Your TV</SecondTitle>
            <SupportGrid>
              <ImgWrap>
                <Img src="/images/431.970_900x.png" alt={"Not found"} />
              </ImgWrap>
              <SupportColumn>
                <ImgWrap>
                  <ImgSmall
                    src="/images/ae302ec79f25bebc3df0494b6e6c2a76.webp"
                    alt={"Not found"}
                  />
                </ImgWrap>
                1. Connect the Audio and Video with TV Using HDMI cable .
              </SupportColumn>
              <SupportColumn>
                <ImgWrap>
                  <ImgSmall
                    src="/images/b223d1eca627431ab843799352e2ac34.webp"
                    alt={"Not found"}
                  />
                </ImgWrap>
                2. Connect the Power power adapter to the power port of TV Box.
              </SupportColumn>
              <SupportColumn>
                <ImgWrap>
                  <ImgSmall
                    src="/images/Cat8-ethernet-cable.webp"
                    alt={"Not found"}
                  />
                </ImgWrap>
                3. Connect the Internet Router using Ethernet Cable.
              </SupportColumn>
            </SupportGrid>
          </ContentRow>
        </ContentWrapper>
      </ContentContainer>
    </>
  );
};

export default SupportSection;
