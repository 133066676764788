import React, { useState } from "react";
import "react-slideshow-image/dist/styles.css";
import { ContentProps } from "./Data";
import styled, { css } from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";
import { ContactRequestClient } from "../../dto/Dtos";
import { useForm } from "react-hook-form";
import { postContactRequest } from "../../controller/ContactController";
import MoonLoader from "react-spinners/MoonLoader";
import { FaCheck } from "react-icons/fa";

interface NavbarProps {
  paragraph1: ContentProps;
}

const ContactSection: React.FC<NavbarProps> = ({ paragraph1 }) => {
  window.scrollTo({ top: 0, behavior: "smooth" });
  const { REACT_APP_API_RECAPTCHA_KEY } = process.env;
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ContactRequestClient>({ mode: "onChange" });

  const override = css`
    display: block;
    position: relative;
    border-color: #313131;
  `;

  const resetSubmit = () => {
    setLoading(false);
    reset(
      {
        name: "",
        mail: "",
        telephoneNumber: "",
        message: "",
      },
      {
        keepValues: false,
      }
    );
  };

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
  };

  const addCallback = (data: ContactRequestClient) => {
    setLoading(true);
    postContactRequest(data, recaptchaToken ? recaptchaToken : "").then(() => {
      setLoading(false);
      setSendSuccess(true);
      setTimeout(() => setSendSuccess(false), 5000);

      resetSubmit();
    });
  };

  return (
    <>
      <LoadHolder>
        {sendSuccess ? (
          <Popup>
            <FaCheck
              size={50}
              style={{
                display: "block",
                color: "green",
                justifySelf: "center",
              }}
            />
            Thank you for contacting us, we will try to reply back as soon as
            possible
          </Popup>
        ) : (
          <MoonLoader
            color="#000000"
            loading={loading}
            css={override}
            size={50}
          />
        )}
      </LoadHolder>
      <ContentContainer lightBg={true} id={paragraph1.id}>
        <ContentWrapper>
          <ContentRow imgStart={paragraph1.imgStart}>
            <Column1>
              <TextWrapper>
                <Heading lightText={paragraph1.headline}>
                  {paragraph1.headline}
                </Heading>
                <TextArea darkText={paragraph1.darkText}>
                  {paragraph1.paragraph}
                </TextArea>
              </TextWrapper>
              <SecondTitle>
                {paragraph1.forSales}
                <SupportColumn>
                  Sweden:{" "}
                  <a href={"tel:" + paragraph1.saleDetailsSE}>
                    {paragraph1.saleDetailsSE}
                  </a>
                </SupportColumn>
                <SupportColumn>
                  Norway:{" "}
                  <a href={"tel:" + paragraph1.saleDetailsNO}>
                    {paragraph1.saleDetailsNO}
                  </a>
                </SupportColumn>
                <SupportColumn>
                  Denmark:{" "}
                  <a href={"tel:" + paragraph1.saleDetailsDK}>
                    {paragraph1.saleDetailsDK}
                  </a>
                </SupportColumn>
                <SupportColumn>
                  Finland:{" "}
                  <a href={"tel:" + paragraph1.saleDetailsFI}>
                    {paragraph1.saleDetailsFI}
                  </a>
                </SupportColumn>
              </SecondTitle>
              <SecondTitle>
                {paragraph1.technicService}
                <SupportColumn>
                  {paragraph1.contactDetails1.country}:&ensp;&ensp;
                  <a href={"tel:" + paragraph1.contactDetails1.number}>
                    {paragraph1.contactDetails1.number}
                  </a>
                </SupportColumn>
                <SupportColumn>
                  {paragraph1.contactDetails2.country}:&ensp;&ensp;
                  <a href={"tel:" + paragraph1.contactDetails2.number}>
                    {paragraph1.contactDetails2.number}
                  </a>
                </SupportColumn>
                <SupportColumn>
                  {paragraph1.contactDetails3.country}:&ensp;&ensp;
                  <a href={"tel:" + paragraph1.contactDetails3.number}>
                    {paragraph1.contactDetails3.number}
                  </a>
                </SupportColumn>
                <SupportColumn>
                  {paragraph1.contactDetails4.country}:&ensp;&ensp;
                  <a href={"tel:" + paragraph1.contactDetails4.number}>
                    {paragraph1.contactDetails4.number}
                  </a>
                </SupportColumn>
              </SecondTitle>
            </Column1>
            <Column2>
              <Form onSubmit={handleSubmit(addCallback)}>
                <TitleBarTable>Contact us</TitleBarTable>
                <Input
                  type="text"
                  width={"100%"}
                  placeholder="Name"
                  {...register("name", { required: true, maxLength: 80 })}
                />
                {errors.name?.type === "required" && (
                  <ErrorMessage>First name is required</ErrorMessage>
                )}
                <Input
                  type="email"
                  placeholder="Mail"
                  width={"49%"}
                  {...register("mail", { required: true, maxLength: 100 })}
                />
                {errors.mail && <ErrorMessage>Mail is required</ErrorMessage>}
                <Input
                  width={"49%"}
                  type="telephoneNumber"
                  placeholder="Telephone (Optional)"
                  {...register("telephoneNumber", {
                    required: false,
                    maxLength: 100,
                  })}
                />
                {errors.telephoneNumber && (
                  <ErrorMessage>Telephone is required</ErrorMessage>
                )}
                <InputText
                  {...register("message", { required: false })}
                  placeholder="Message"
                />
                {errors.message && (
                  <ErrorMessage>Message is required</ErrorMessage>
                )}
                <ReCAPTCHA
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "10px",
                    width: "100%",
                  }}
                  sitekey={REACT_APP_API_RECAPTCHA_KEY as string}
                  onChange={handleRecaptchaChange}
                />
                <InputButton type="submit" value={"Send"} />
              </Form>
            </Column2>
          </ContentRow>
        </ContentWrapper>
      </ContentContainer>
    </>
  );
};

export default ContactSection;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  gap: 2%;
  justify-content: center;

  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
`;

const ContentContainer = styled.div`
  color: #fff;
  margin-top: 200px;

  background: ${({ primaryBg }) =>
    primaryBg ? "var(--main-bg-color)" : "var(--secondary-bg-color)"};

  @media screen and (max-width: 768px) {
    padding: 100px 0;
    margin-top: 0px;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  z-index: 1;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }
`;

const ContentRow = styled.div`
  display: grid;
  margin-bottom: 100px;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `"col2 col1"` : `"col1 col2"`};

  @media screen and (max-width: 768px) {
    margin-bottom: 0;
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `"col1" "col2"` : `"col1 col1" "col2 col2"`};
  }
`;

const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
  width: 25vw;
  @media screen and (max-width: 768px) {
    margin-top: 15px;
    width: 100%;
  }
`;

const Column2 = styled.div`
  padding: 0 15px;
  align-self: baseline;
  grid-area: col2;
  text-align: end;
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
    padding: 0 0;
  }
`;

const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
`;

const Heading = styled.h1`
  margin-bottom: 14px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) =>
    lightText ? "var(--main-text-color)" : "var(--secondary-text-color)"};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

const TextArea = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 15px;
  color: ${({ darkText }) =>
    darkText ? "var(--secondary-text-color)" : "var(--main-text-color)"};
`;

const SecondTitle = styled.div`
  color: black;
  display: block;
  margin-bottom: 20px;
  width: fit-content;
  font-size: 1.7rem;
  display: grid;
  margin-bottom: 50px;
  grid-gap: 5px;
  align-items: center;
`;

const SupportColumn = styled.div`
  font-size: 18px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

interface InputProps {
  width: string;
}

const Input = styled.input<InputProps>`
  background: #fff;
  color: #000;
  width: ${({ width }) => (width ? width : "100%")};
  border-radius: 10px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  height: 35px;
  margin-bottom: 10px;
  border-color: transparent;
  text-align: center;
  &:active {
    background-color: #8b8b8b;
  }
  @media screen and (max-width: 450px) and (max-height: 950px) {
    width: 100%;
  }

  @media screen and (max-width: 400px) and (max-height: 700px) {
    height: 30px;
    font-size: 10px;
  }
`;

const InputText = styled.textarea`
  background: #fff;
  color: #000;
  width: 100%;
  height: 200px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  margin-bottom: 10px;
  border-color: transparent;

  &:active {
    background-color: #8b8b8b;
  }

  @media screen and (max-width: 400px) and (max-height: 700px) {
    height: 50px;
    font-size: 10px;
  }
`;

const InputButton = styled.input.attrs({ type: "submit" })`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 24px;
  margin-bottom: 20px;
  background: #141414;
  color: #fff;
  border: none;
  border-radius: 10px;
  width: 80%;
  &:active {
    background-color: #8b8b8b;
  }

  @media screen and (max-width: 400px) and (max-height: 700px) {
    font-size: 12px;
    height: 30px;
  }
`;

const ErrorMessage = styled.span`
  color: red;
`;

const TitleBarTable = styled.div`
  color: white;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 30px;
  background: #000;
  margin-bottom: 10px;
  display: flex;
`;

const LoadHolder = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
`;

const Popup = styled.div`
  display: grid;
  padding: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: solid 5px black;
  border-radius: 10px;
  background: white;
  width: 400px;
  height: 150px;
`;
