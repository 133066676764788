import { Article } from "../../dto/Dtos";

export function addToCart(id: number) {
  var items = getCartItems();
  items.push(id);
  setAndDispatchEvent(JSON.stringify(items));
}

export function removeFromCart(index: number) {
  var items = getCartItems();
  items.splice(index, 1);
  setAndDispatchEvent(JSON.stringify(items));
}

export function clearCart() {
  setAndDispatchEvent(JSON.stringify([]));
}
export function getCartItems(): number[] {
  var itemsJson = localStorage.getItem("cartItems");

  return itemsJson ? (JSON.parse(itemsJson) as number[]) : [];
}

function setAndDispatchEvent(jsonItems: string) {
  localStorage.setItem("cartItems", jsonItems);
  const event = new Event("itemUpdated");
  window.dispatchEvent(event);
}

export function getPriceFromCountryAsText(
  article: Article,
  country: string
): string {
  if (country === "Sweden") {
    return article.priceSEK + " SEK";
  }
  if (country === "Denmark") {
    return article.priceDKK + " DKK";
  }
  if (country === "Norway") {
    return article.priceNOK + " NOK";
  }

  return article.priceEURO + " EURO";
}

export interface PriceWithCurrency {
  price: number;
  currency: string;
}

export function getPriceFromCountry(
  article: Article,
  country: String
): PriceWithCurrency {
  if (country === "Sweden") {
    return { price: article.priceSEK, currency: " SEK" };
  }
  if (country === "Denmark") {
    return { price: article.priceDKK, currency: " DKK" };
  }
  if (country === "Norway") {
    return { price: article.priceNOK, currency: " NOK" };
  }

  return { price: article.priceEURO, currency: " EURO" };
}
