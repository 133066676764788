export interface Article {
  id: number;
  name: string;
  description: string;
  priceSEK: number;
  priceNOK: number;
  priceDKK: number;
  priceEURO: number;
  sale: number;
  inStock: number;
  priority: number;
  imagePath: string;
  localPrice: number;
  hide: boolean;
  isShipment: boolean;
  text1: string;
  text2: string;
  text3: string;
  text4: string;
}

export interface Currency {
  countryCode: string;
  rate: number;
}

export interface ArticleWrapper {
  pages: number;
  articles: Article[];
}

export interface ArticleRequest {
  name: string;
  address: string;
  postcode: string;
  locality: string;
  country: string;
  mail: string;
  telephoneNumber: string;
  message: string;
  articleIds: number[];
}

export interface ArticleRequestDTO {
  "@class": "com.chitram.v1.model.ArticleRequestDTO";
  id: number;
  orderNumber: number;
  parcelNumber: string;
  name: string;
  address: string;
  postcode: string;
  locality: string;
  country: string;
  telephoneNumber: string;
  mail: string;
  message: string;
  location: string;
  ipAddress: string;
  date: Date;
  hasSeen: boolean;
  hasProcessed: boolean;
  archived: boolean;
  totalPriceSEK: number;
  totalPriceNOK: number;
  totalPriceDKK: number;
  totalPriceEURO: number;
  articles: Article[];
}

export interface IpInfo {
  ip: string;
  network: string;
  version: string;
  city: string;
  region: string;
  region_code: string;
  country: string;
  country_name: string;
  country_code: string;
  country_code_iso3: string;
  country_capital: string;
  country_tld: string;
  continent_code: string;
  in_eu: boolean;
  postal: string;
  latitude: number;
  longitude: number;
  timezone: string;
  utc_offset: string;
  country_calling_code: string;
  currency: string;
  currency_name: string;
  languages: string;
  country_area: number;
  country_population: number;
  asn: string;
  org: string;
}

export interface ContactRequestClient {
  name: string;
  mail: string;
  telephoneNumber: string | null;
  message: string;
}

export enum ShopView {
  Main,
  Preview,
}

export enum CartView {
  Cart,
  Confirmation,
}

export interface MessageToClient {
  id: number;
  message: string;
}
