// WhatsAppIcon.tsx
import React, { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import styled from "styled-components";

interface WhatsAppIconProps {
  phoneNumber: string;
}

const WhatsAppIcon: React.FC<WhatsAppIconProps> = ({ phoneNumber }) => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const handleWhatsAppClick = () => {
    // Replace 'your_message' with any default message you want to include
    const message = encodeURIComponent("Hello! I want to chat.");
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <Container onClick={handleWhatsAppClick}>
      <FaWhatsapp size={isMobile ? 35 : 50} color="#25d366" />
      <ContactUS>Contact us</ContactUS>
    </Container>
  );
};

export default WhatsAppIcon;

const Container = styled.div`
  position: fixed;
  padding: 10px;
  border-radius: 50%;
  bottom: 20px;
  right: 20px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    bottom: 20px;
    right: 0px;
  }
`;

const ContactUS = styled.div`
  display: block;
  font-weight: 600;
  margin-bottom: 5px;
  color: #179e48;
  width: 80px;

  @media screen and (max-width: 768px) {
    font-size: 12px;
    width: 60px;
  }
`;
