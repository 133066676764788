import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import "react-notifications/lib/notifications.css";
import EditPopup from "./EditPopup";

import {
  NotificationContainer,
  NotificationManager,
  //@ts-expect-error
} from "react-notifications";
import AddPopup from "./AddPopup";
import {
  deleteArticle,
  deleteImages,
  fetchArticles,
  saveItem,
} from "../../controller/AdminController";
import { Article } from "../../dto/Dtos";
import { InternalError, UnAuthorizedError } from "../../dto/CustomErrors";

const AdminProducts = () => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [searchPhrase, setSearchPhrase] = useState<number>();

  const [showItem, setShowItem] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const [selectedItem, setSelectedItem] = useState([]);
  const { REACT_APP_API_HOST } = process.env;
  let navigate = useNavigate();

  //@ts-expect-error
  function save(updatedItem, item) {
    if (item.id !== null) {
      updatedItem.id = item.id;
    }
    updatedItem["@class"] = item["@class"];
    console.log(updatedItem);
    saveItem(updatedItem).then((res) => {
      if (!res.ok) {
        console.log("YEES");
        NotificationManager.error(
          "Hoppsan, ett fel uppstod! Vänligen kontakta Aigeth..."
        );
        return;
      }
      const getArticles = async () => {
        try {
          const articlesFromServer = await fetchArticles();
          setArticles(articlesFromServer);
        } catch (e) {
          if (e instanceof UnAuthorizedError) {
            navigate("/login");
          } else if (e instanceof InternalError) {
            window.alert(e.message);
          }
        }
      };

      getArticles();
      setShowAdd(false);
      setShowItem(false);
    });
  }
  //@ts-expect-error
  const openItem = (article) => {
    setSelectedItem(article);
    setShowItem((prev) => !prev);
  };

  useEffect(() => {
    const getArticles = async () => {
      try {
        const articlesFromServer = await fetchArticles();
        setArticles(articlesFromServer);
      } catch (e) {
        if (e instanceof UnAuthorizedError) {
          navigate("/login");
        } else if (e instanceof InternalError) {
          window.alert(e.message);
        }
      }
    };

    getArticles();
  }, [navigate]);

  const findItem = () => {
    const article = articles.find((article) => article.id === searchPhrase);
    openItem(article);
  };

  const deleteUnsetImages = useCallback(async () => {
    try {
      deleteImages();
    } catch (e) {
      if (e instanceof UnAuthorizedError) {
        navigate("/login");
      } else if (e instanceof InternalError) {
        window.alert(e.message);
      }
    }
  }, [navigate]);

  const deleteSelectedArticles = async () => {
    if (!window.confirm("Är du säker på att du vill ta bort?")) {
      return;
    }

    try {
      const articlesFromServer = await deleteArticle(selectedItems);
      setArticles(articlesFromServer);
      setSelectedItems([]);
    } catch (e) {
      if (e instanceof UnAuthorizedError) {
        navigate("/login");
      } else if (e instanceof InternalError) {
        window.alert(e.message);
      }
    }
  };

  return (
    <>
      <TillbakaContainer>
        <Button onClick={() => window.history.back()} enabled={true}>
          Tillbaka
        </Button>
      </TillbakaContainer>

      <InputArea>
        <Input
          placeholder="Sök Id"
          onChange={(event) => setSearchPhrase(parseInt(event.target.value))}
          onKeyPress={(e) => {
            console.log(e.key);
            if (e.key === "Enter") {
              findItem();
            }
          }}
        />
        <Button onClick={findItem} enabled={true}>
          Ok{" "}
        </Button>
        <Button onClick={() => setShowAdd((prev) => !prev)} enabled={true}>
          Lägg till
        </Button>
        <Button
          onClick={() => {
            if (selectedItems.length > 0) {
              deleteSelectedArticles();
            }
          }}
          enabled={selectedItems.length > 0}
        >
          Tabort markerad ({selectedItems.length})
        </Button>
      </InputArea>

      <Page>
        {showItem ? (
          <EditPopup
            showItem={showItem}
            setShowItem={setShowItem}
            selectedItem={selectedItem}
            save={save}
          />
        ) : null}
        {showAdd ? (
          <AddPopup
            showItem={showAdd}
            setShowItem={setShowAdd}
            save={save}
            deleteUnsetImages={deleteUnsetImages}
          />
        ) : null}
        {articles.map((article) => (
          <Item key={article.id}>
            <RemoveSelection
              onChange={(event) => {
                if (event.target.checked) {
                  setSelectedItems((oldItems) => [...oldItems, article.id]);
                } else {
                  setSelectedItems((currentItems) =>
                    currentItems.filter((item) => item !== article.id)
                  );
                }
              }}
            />

            <ImagePreview onClick={() => openItem(article)}>
              <PreviewImage
                src={
                  REACT_APP_API_HOST +
                  "/api/v2/article/image/thumbnail/" +
                  article.imagePath
                }
                alt="test"
              />
            </ImagePreview>
            <Text>ID: {article.id}</Text>
            <Text>
              Name: <br></br> {article.name}
            </Text>
          </Item>
        ))}
        <NotificationContainer />
      </Page>
    </>
  );
};

export default AdminProducts;

const Page = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding-left: 200px;
  padding-right: 200px;
  align-items: center;
  justify-content: center;
  border-color: black;
  text-align: center;
  border: 5px;

  @media screen and (max-width: 1280px) {
    grid-template-columns: 1fr 1fr;
    padding: 0;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
`;

const TillbakaContainer = styled.div`
  width: 100%;
  padding-left: 10%;
`;

const Item = styled.div`
  width: 300px;
  height: 500px;
  display: grid;
  background-color: #ffffff;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  justify-self: center;

  @media screen and (max-width: 1280px) {
    grid-template-columns: 1fr;
  }
`;

const Text = styled.div`
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

const PreviewImage = styled.img`
  display: inline;
  height: 280px;
  width: 280px;
`;

const ImagePreview = styled.div`
  width: fit-content;
  @media screen and (max-width: 768px) {
    display: inline;
  }
`;

const InputArea = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const Input = styled.input`
  margin-top: 5px;
  height: 30px;
  display: block;
  width: 150px;
  padding-left: 5px;

  text-align: center;
`;

const Button = styled.button`
  display: inline;
  margin-top: 6px;
  margin-left: 10px;
  height: 35px;
  padding: 10px 24px;
  color: #fff;
  border: none;
  background: ${({ enabled }) => (enabled ? "#141414" : "#818181")};
`;

const RemoveSelection = styled.input.attrs({ type: "checkbox" })`
  position: relative;
  display: block;
  height: 35px;
  width: 35px;
  top: 0;
  left: 0;
`;
