import styled from "styled-components";

export const ContentContainer = styled.div`
  color: #fff;
  margin-top: 85px;
  background: ${({ primaryBg }) =>
    primaryBg ? "var(--main-bg-color)" : "var(--secondary-bg-color)"};

  @media screen and (max-width: 768px) {
    padding: 85px 0;
    margin-top: 0px;
  }
`;

export const ContentWrapper = styled.div`
  display: grid;
  z-index: 1;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }
`;

export const ContentRow = styled.div`
  display: grid;
  margin-bottom: 100px;
  grid-auto-columns: 1fr;
  align-items: center;

  @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 15px;
  width: 100vw;
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
    padding: 0;
  }
`;

export const Column2 = styled.div`
  text-align: center;
  justify-content: center;
  display: grid;
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
    margin-top: 10px;
    padding: 0 15px;
  }
`;

export const TextWrapper = styled.div`
  padding: 0 15%;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const BottomLine = styled.p`
  color: var(--title-text-color);
  font-size: 18px;
  line-height: 16px;
  font-weight: 800;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 14px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) =>
    lightText ? "var(--main-text-color)" : "var(--secondary-text-color)"};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) =>
    darkText ? "var(--secondary-text-color)" : "var(--main-text-color)"};
`;

export const TextArea = styled.p`
  margin-bottom: 35px;
  font-size: 16px;
  color: ${({ darkText }) =>
    darkText ? "var(--secondary-text-color)" : "var(--main-text-color)"};
`;

export const BtnWrap = styled.div`
  display: flex;
  padding-top: 10px;
  margin-bottom: 10px;
  justify-content: center;
`;

export const BtnWrap2 = styled.div`
  padding-top: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

export const ImgWrap = styled.div`
  width: 100%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
  @media screen and (max-width: 768px) {
    height: 200px;
  }
`;

export const ImgBtn = styled.img`
  width: 50%;
  margin: 0 0 10px 0;
  padding-right: 0;
  @media screen and (max-width: 768px) {
    height: 200px;
    width: 100%;
  }
`;

export const FeatureWrapper = styled.div`
  display: grid;
  width: 100%;
  padding: 0 15%;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 36px;
  max-width: 100vw;
  @media screen and (max-width: 768px) {
    grid-gap: 8px;
    padding: 0 50px;
    grid-template-columns: 1fr;
  }
`;

export const FeatureTitleImgWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: fit-content;

  @media screen and (max-width: 768px) {
    grid-gap: 8px;
    grid-template-columns: 1fr;
  }
`;

export const FeatureTitle = styled.p`
  color: black;
  margin-bottom: 20px;
  font-size: 2rem;
`;

export const FeatureText = styled.p`
  color: black;
  margin-bottom: 20px;
  font-size: 1rem;
`;

export const FeatureCard = styled.div`
  align-items: center;
  text-align: center;
  border-radius: 20px;
  background-color: white;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  //padding: 20px 10px 20px 10px;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    padding: 10px 5px;
    margin-bottom: 0px;
    height: 100%;
  }
`;
export const FeatureImgWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const FeatureTitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const FeatureImg = styled.img`
  width: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  margin-bottom: 20px;
`;

export const ShopButton = styled.button`
  padding: 10px 24px;
  cursor: pointer;
  color: #fff;
  margin-top: 20px;
  width: 200px;
  border-radius: 15px;
  border: none;
  justify-self: center;
  background: var(--button-primary-bgcolor);
`;
