import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { navbarItems } from "../NavbarContent";
import DownloadSection from "../components/DownloadSection/Downloads";
const DownloadsPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} navbarItems={navbarItems} />
      <Navbar toggle={toggle} setScroll={false} navbarItems={navbarItems} />
      <DownloadSection />
    </>
  );
};

export default DownloadsPage;
