import React, { useEffect, useState } from "react";
import { ArticleRequestDTO } from "../../dto/Dtos";
import {
  fetchArticleRequests,
  removeRequest,
} from "../../controller/AdminController";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
} from "@mui/material";
import {
  Warning,
  Drafts,
  Email,
  Check,
  RemoveCircle,
} from "@mui/icons-material";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { InternalError, UnAuthorizedError } from "../../dto/CustomErrors";
interface ArticleTableProps {
  showArchived: boolean;
}

const ArticleTable: React.FC<ArticleTableProps> = ({ showArchived }) => {
  const [articleRequests, setArticleRequests] = useState<ArticleRequestDTO[]>(
    []
  );
  let navigate = useNavigate();

  const fetchData = React.useCallback(async () => {
    try {
      const data = await fetchArticleRequests();
      setArticleRequests(data);
    } catch (e) {
      if (e instanceof UnAuthorizedError) {
        navigate("/login");
      } else if (e instanceof InternalError) {
        window.alert(e.message);
      }
    }
  }, [navigate, setArticleRequests]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Paper>
      <Table style={{ width: "1400px" }}>
        <TableHead>
          <TableRow>
            <TableCell>Oläst/Läst</TableCell>
            <TableCell>Har Bearbetats</TableCell>
            <TableCell>ORDER NR</TableCell>
            <TableCell style={{ width: "400px" }}>Name</TableCell>
            <TableCell>Telephone Number</TableCell>
            <TableCell>Mail</TableCell>
            <TableCell>Message</TableCell>
            <TableCell style={{ width: "300px" }}>Date</TableCell>
            <TableCell style={{ width: "300px" }}></TableCell>
            <TableCell style={{ width: "30px" }}>Arkivera</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {articleRequests
            .filter(
              (i) =>
                (showArchived && i.archived) || (!showArchived && !i.archived)
            )
            .map((request) => (
              <TableRow
                style={request.hasSeen ? {} : { background: "lightyellow" }}
                key={request.id}
              >
                <TableCell>
                  {request.hasSeen ? (
                    <Drafts style={{ color: "#53a05a" }} />
                  ) : (
                    <Email style={{ color: "#938900" }} />
                  )}
                </TableCell>
                <TableCell>
                  {!request.hasProcessed ? (
                    <Warning style={{ color: "#a10000" }} />
                  ) : (
                    <Check style={{ color: "#53a05a" }} />
                  )}
                </TableCell>
                <TableCell>{request.orderNumber}</TableCell>
                <TableCell>{request.name}</TableCell>
                <TableCell>{request.telephoneNumber}</TableCell>
                <TableCell>{request.mail}</TableCell>
                <TableCell>{request.message}</TableCell>
                <TableCell>
                  {new Date(request.date).toLocaleDateString()} &nbsp;
                  {new Date(request.date).toLocaleTimeString()}
                </TableCell>
                <TableCell>
                  <Button
                    isProcessed={request.hasProcessed}
                    onClick={() => navigate("/admin/requests/" + request.id)}
                  >
                    Öppna förfrågan
                  </Button>
                </TableCell>
                <TableCell>
                  <RemoveCircle
                    style={{ color: "#c72222", cursor: "pointer" }}
                    onClick={() => {
                      if (
                        !window.confirm("Är du säker på att du vill ta bort?")
                      ) {
                        return;
                      }
                      removeRequest(request.id).then(() => {
                        fetchData();
                      });
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default ArticleTable;

const Button = styled.button`
  display: inline;
  margin-top: 6px;
  margin-left: 10px;
  height: 35px;
  padding: 10px 24px;
  color: #fff;
  border: none;
  cursor: pointer;
  background: ${({ isProcessed }) => (isProcessed ? "#53a05a" : "#a10000")};
`;
