import React from "react";

import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
} from "./FooterElement";
import WhatsAppIcon from "../FloatingIcons/WhatsAppIcon";
import ShoppingCartIcon from "../FloatingIcons/ShoppingCartIcon";
//              <FooterLink to="/terms">Terms of Serivce</FooterLink>

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Links</FooterLinkTitle>
              <FooterLink to="/support">FAQ</FooterLink>
              <FooterLink to="/privacy">Privacy Policy</FooterLink>
              <FooterLink to="/contact">Contact us</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <SocialMedia>
            <SocialMediaWrap>
              <WebsiteRights>
                Copyright © 2023 Chitram.se Store All rights reserved.
              </WebsiteRights>
            </SocialMediaWrap>
          </SocialMedia>
        </FooterLinksContainer>
      </FooterWrap>
      <WhatsAppIcon phoneNumber={"+46704859228"} />
      <ShoppingCartIcon />
    </FooterContainer>
  );
};

export default Footer;
