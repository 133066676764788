export interface ContentProps {
  id: string; // Assuming Person is an interface or type that you have defined
  headline: string;
  paragraph: string;
  forSales: string;
  saleDetailsSE: string;
  saleDetailsNO: string;
  saleDetailsDK: string;
  saleDetailsFI: string;
  technicService: string;
  contactDetails1: PhoneProps;
  contactDetails2: PhoneProps;
  contactDetails3: PhoneProps;
  contactDetails4: PhoneProps;
  image: string;
  alt: string;
  primaryBg: boolean;
  primary: boolean;
  darkText: boolean;
}

export interface PhoneProps {
  country: string;
  number: string;
}

export const paragraph1: ContentProps = {
  id: "contact",
  headline: "Chitram TV Customer Support",
  paragraph:
    "Chitram’s customer service always comes with the peace of mind you deserve. Informative and time-saving tools, guides, videos, blogs, and forums to help you solve virtually any Chitram TV issues, you have all online and available 24/7.",
  forSales: "Sales",
  saleDetailsSE: "+46 704859228",
  saleDetailsNO: "+47 45855033",
  saleDetailsDK: "+45 25347468",
  saleDetailsFI: "+358 407422344",
  technicService: "Technic support",
  contactDetails1: { country: "Germany", number: "+49 3021782567" },
  contactDetails2: { country: "Worldwide", number: "+33 975180853" },
  contactDetails3: { country: "Europe", number: "+33 975180853" },
  contactDetails4: { country: "UK", number: "+44 2038686906" },
  image: "/images/PngItem_1466981.webp",
  alt: "Not available",
  primaryBg: false,
  primary: true,
  darkText: false,
};
