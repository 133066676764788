import React, { useRef, useCallback, useEffect, useState } from "react";
import { useSpring, animated } from "react-spring";
import { MdClose } from "react-icons/md";
import styled from "styled-components";
import ReactDom from "react-dom";
import ReactSelect from "react-select";
import makeAnimated from "react-select/animated";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/react";
import axios from "axios";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const { REACT_APP_API_HOST } = process.env;
const override = css`
  display: block;
  position: relative;
  border-color: #000000;
`;

export default function AddPopup({
  showItem,
  setShowItem,
  save,
  deleteUnsetImages,
}) {
  const [item, setItem] = useState({ images: [], categories: [] });
  const [description, setDescription] = useState("Pre order");
  const [selectedFile, setSelectedFile] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filters, setFilters] = useState([]);

  const [loading, setLoading] = useState(false);

  const [type, setType] = useState([]);
  const animatedComponents = makeAnimated();

  const itemRef = useRef();

  const animation = useSpring({
    config: {
      duration: 350,
    },
    opacity: showItem ? 1 : 0,
  });

  const closeItemPreview = (e) => {
    if (itemRef.current === e.target) {
      setShowItem(false);
    }
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showItem) {
        setShowItem(false);
      }
    },
    [showItem, setShowItem]
  );

  const fileSelectedHandler = () => {
    setLoading(true);
    const fd = new FormData();
    fd.append("description", JSON.stringify(description));
    fd.append("image", selectedFile, selectedFile.name);
    axios
      .post(REACT_APP_API_HOST + "/api/admin/v1/upload", fd, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        },
      })
      .then((res) => {
        const changedItem = Object.assign({}, item);
        changedItem.images.push(res.data);
        if (changedItem.images.length === 1) {
          changedItem.firstImage = res.data;
        }
        setItem(changedItem);
        console.log(changedItem);
        selectedFile.fileName = "";
        setSelectedFile(selectedFile);
        setDescription("");
      })
      .catch((e) => {
        NotificationManager.error(e.toJSON().message);
      })
      .finally(() => setLoading(false));
  };

  const handleChange = (event, key) => {
    const changedItem = Object.assign({}, item);
    if (typeof changedItem[key] === "number") {
      if (isNaN(parseInt(event))) {
        event = 0;
      }
      changedItem[key] = parseInt(event);
    } else {
      changedItem[key] = event;
    }
    setItem(changedItem);
  };

  const handleObjectChange = (value, key) => {
    const changedItem = Object.assign({}, item);
    if (isNaN(parseInt(value))) {
      value = 0;
    }
    changedItem[key] = { id: parseInt(value) };
    setItem(changedItem);
  };

  const handleArrayChange = (value, key, index) => {
    const changedItem = Object.assign({}, item);
    const array = changedItem[key];
    if (index < array.length) {
      if (value === "") {
        array.splice(index, 1);
      } else {
        array[index] = { id: parseInt(value) };
      }
    } else if (value !== "") {
      array.push({ id: parseInt(value) });
    }
    changedItem[key] = array;
    setItem(changedItem);
  };

  const addToArray = (change, key) => {
    if (change === undefined || change === null) {
      return;
    }
    const changedItem = Object.assign({}, item);
    const array = changedItem[key];
    array.push({ id: change.value });
    changedItem[key] = array;
    setItem(changedItem);
  };

  const getProperSelection = (key) => {
    if (key === "categories") {
      return categories;
    }
    if (key === "filters") {
      return filters;
    }
  };

  function populateArray(key, array) {
    const arr = [];
    arr.push(
      <CustomSelect
        key={key}
        isClearable
        classNamePrefix={"Select"}
        closeMenuOnSelect={false}
        components={animatedComponents}
        placeholder={"Välj"}
        options={getProperSelection(key)}
        styles={categorySelectionStyle}
        menuPlacement="top"
        aria-orientation="horizontal"
        onChange={(change) => addToArray(change, key)}
      />
    );
    for (let i = 0; i < array.length; i++) {
      arr.push(
        <SubInput
          key={i}
          value={array[i].id}
          onChange={(event) => handleArrayChange(event.target.value, key, i)}
        />
      );
    }
    arr.push(
      <SubInput
        key={arr.length}
        value=""
        onChange={(event) =>
          handleArrayChange(event.target.value, key, arr.length)
        }
      />
    );
    return arr;
  }

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => {
      document.removeEventListener("keydown", keyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    window.onpopstate = (e) => {
      setShowItem(false);
    };
    return () => {
      setShowItem(false);
    };
  }, [setShowItem]);

  const fetchArticle = useCallback(async (type) => {
    var typeToFetch;
    if (type === null) {
      typeToFetch = "dummyArticle";
    } else if (type.label === "Saree") {
      typeToFetch = "dummySaree";
    } else if (type.label === "Blouse") {
      typeToFetch = "dummyBlouse";
    } else if (type.label === "Veshti") {
      typeToFetch = "dummyVeshti";
    } else if (type.label === "Pavadasattai") {
      typeToFetch = "dummyPavadasattai";
    } else if (type.label === "Clothing") {
      typeToFetch = "dummyClothing";
    } else {
      typeToFetch = "dummyArticle";
    }

    const res = await fetch(
      REACT_APP_API_HOST + "/api/admin/v1/" + typeToFetch,
      {
        credentials: "same-origin",
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        },
      }
    );
    const data = await res.json();
    return data;
  }, []);

  useEffect(() => {
    const fetchAllCategories = async () => {
      const res = await fetch(REACT_APP_API_HOST + "/api/admin/v1/categories", {
        credentials: "same-origin",
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        },
      });
      const data = await res.json();
      const arr = data.map((item) => ({
        value: item.id,
        label: item.fullName,
      }));
      setCategories(arr);
    };

    const fetchAllFilters = async () => {
      const res = await fetch(REACT_APP_API_HOST + "/api/admin/v1/filters", {
        credentials: "same-origin",
        withCredentials: true,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("access_token"),
        },
      });
      const data = await res.json();
      const arr = data.map((item) => ({
        value: item.id,
        label: item.fullName,
      }));
      setFilters(arr);
    };
    fetchAllCategories();
    fetchAllFilters();
  }, []);

  useEffect(() => {
    const getArticle = async () => {
      const articleFromServer = await fetchArticle(type);
      if (item.name != null) {
        articleFromServer.name = item.name;
      }
      if (item.images != null) {
        articleFromServer.images = item.images;
      }
      if (item.price != null) {
        articleFromServer.price = item.price;
      }
      if (item.sale != null) {
        articleFromServer.sale = item.sale;
      }
      if (item.inStock != null) {
        articleFromServer.inStock = item.inStock;
      }
      if (item.colorCorrection != null) {
        articleFromServer.colorCorrection = item.colorCorrection;
      }
      if (item.priority != null) {
        articleFromServer.priority = item.priority;
      }
      if (item.firstImage != null) {
        articleFromServer.firstImage = item.firstImage;
      }
      if (item.preOrder != null) {
        articleFromServer.preOrder = item.preOrder;
      }
      if (item.categories != null) {
        articleFromServer.categories = item.categories;
      }
      if (item.filters != null) {
        articleFromServer.filters = item.filters;
      }
      if (item.validTo != null) {
        articleFromServer.validTo = item.validTo;
      }
      setItem(articleFromServer);
    };
    getArticle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchArticle, type]);

  return ReactDom.createPortal(
    <>
      {showItem ? (
        <Background ref={itemRef} onClick={closeItemPreview}>
          <LoadHolder>
            <MoonLoader
              color="#000000"
              loading={loading}
              css={override}
              size={50}
            />
          </LoadHolder>
          <animated.div style={animation}>
            <ItemWrapper>
              <WideInput
                type="file"
                value={selectedFile.fileName}
                onChange={(event) => setSelectedFile(event.target.files[0])}
              />
              <WideInput
                placeholder="Bild namn"
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />

              <AddButton onClick={fileSelectedHandler}>Upload Image</AddButton>

              <ItemContent>
                <SpecArea>
                  <CustomSelect
                    isClearable
                    classNamePrefix={"Select"}
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    placeholder={"Typ"}
                    options={[
                      { value: "@dsfdsf", label: "Saree" },
                      { value: "@dsfdsf", label: "Blouse" },
                      { value: "@dsfdsf", label: "Veshti" },
                      { value: "@dsfdsf", label: "Article" },
                      { value: "@dsfdsf", label: "Pavadasattai" },
                      { value: "@dsfdsf", label: "Clothing" },
                    ]}
                    styles={customStyles}
                    aria-orientation="horizontal"
                    margin={"0 0 10px 5px"}
                    onChange={(change) => setType(change)}
                  />
                  {Object.entries(item)
                    .filter(
                      ([key, value]) =>
                        key !== "@class" &&
                        key !== "id" &&
                        key !== "images" &&
                        key !== "text1" &&
                        key !== "text2" &&
                        key !== "text3" &&
                        key !== "text4"
                    )
                    .map(([key, value]) => {
                      if (Array.isArray(value)) {
                        return (
                          <Spec key={key}>
                            {key}
                            <SubInputArea>
                              {populateArray(key, value)}
                            </SubInputArea>
                          </Spec>
                        );
                      } else if (key === "validTo") {
                        if (value === null) {
                          item[key] = "";
                        }
                        return (
                          <Spec key={key}>
                            {key}
                            <Input
                              value={value !== null ? value : ""}
                              onChange={(event) =>
                                handleChange(event.target.value, key)
                              }
                            />
                          </Spec>
                        );
                      } else if (typeof value === "object") {
                        return (
                          <Spec key={key}>
                            {key}
                            <Input
                              value={value !== null ? value.id : ""}
                              onChange={(event) =>
                                handleObjectChange(event.target.value, key)
                              }
                            />
                          </Spec>
                        );
                      } else if (typeof value === "boolean") {
                        return (
                          <Spec key={key}>
                            {key}
                            <Checkbox
                              checked={value !== null ? value : false}
                              onChange={(event) => {
                                handleChange(event.target.checked, key);
                                if (
                                  key === "preOrder" &&
                                  !event.target.checked
                                ) {
                                  setDescription("");
                                } else if (
                                  key === "preOrder" &&
                                  event.target.checked
                                ) {
                                  setDescription("Pre order");
                                }
                              }}
                            />
                          </Spec>
                        );
                      } else {
                        return (
                          <Spec key={key}>
                            {key}
                            <Input
                              value={value !== null ? value : ""}
                              onChange={(event) =>
                                handleChange(event.target.value, key, value)
                              }
                            />
                          </Spec>
                        );
                      }
                    })}
                </SpecArea>
                <AddButton onClick={() => save(item, item)}>Save</AddButton>
              </ItemContent>
              <CloseItemPreviewButton
                aria-label="Close"
                onClick={() => {
                  setShowItem(false);
                  deleteUnsetImages();
                }}
              />
            </ItemWrapper>
          </animated.div>
          <NotificationContainer />
        </Background>
      ) : null}
    </>,
    document.getElementById("portal")
  );
}

const Background = styled.div`
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  transform: translateZ(0);
  overflow: hidden;

  @media screen and (max-width: 768px) {
    padding: 0 15px;
  }
`;

const ItemWrapper = styled.div`
  width: 100%;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  grid-template-columns: 1fr 0.1fr 1fr;
  position: relative;
  z-index: 100;
  border-radius: 10px;
  overflow: scroll;
  height: 90vh;

  h1 {
    margin-bottom: -5px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  line-height: 1.8;
  color: #141414;
  padding-bottom: 5px;
`;

/*const PreviewImage = styled.img`
  height: 80px;
  width: 80px;
  border: ${({ firstImage }) => (firstImage ? "4px solid #433ac7" : "none")};
  @media screen and (max-width: 360px) {
    width: 50px;
    height: 50px;
  }
`;

const ImagePreview = styled.div`
  height: 80px;
  flex-direction: column;
  width: fit-content;
  display: grid;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    display: inline;
  }
`;*/

const SpecArea = styled.div`
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
`;

const Spec = styled.div`
  padding: 5px;
  display: block;
  line-height: 0.9rem;
  font-size: 0.8rem;
  margin-top: 5px;
`;

const Input = styled.input`
  margin-top: 5px;
  height: 30px;
  display: block;
  width: 40vw;
  padding-left: 5px;
`;

const Checkbox = styled.input.attrs({ type: "checkbox" })`
  margin-top: 5px;
  height: 25px;
  display: block;
  width: 25px;
  padding-left: 5px;
`;

const WideInput = styled.input`
  margin-top: 5px;
  height: 30px;
  display: block;
  width: 85%;
  padding-left: 5px;
`;

const SubInputArea = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const SubInput = styled.input`
  margin-top: 5px;
  margin-right: 5px;
  padding-left: 5px;
  height: 30px;
  display: inline;
  width: 30px;
  text-align: center;
`;

const CloseItemPreviewButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 1;
`;

const AddButton = styled.button`
  margin-top: 5px;
  padding: 10px 24px;
  color: #fff;
  border: none;
  background: #141414;

  @media screen and (max-width: 360px) {
    height: 40px;
  }
`;

const CustomSelect = styled(ReactSelect)`
  margin: 0 5px 0 0;

  & .Select__indicator Select__dropdown-indicator {
    border-color: transparent transparent blue;
    border-radius: 50px;
  }

  &.Select.is-open > .Select-control .Select-arrow {
    border-color: transparent transparent red;
  }
  .react-select__option {
    border-radius: 1000px;
  }

  @media screen and (max-width: 768px) {
    margin: ${(props) => props.margin};
  }
`;

const customStyles = {
  control: (styles) => ({
    ...styles,
    borderRadius: "30px",
    border: "1",
    background: "#fff",
    marginTop: "20px",
    height: "20px",
  }),
};

const categorySelectionStyle = {
  control: (styles) => ({
    ...styles,
    borderRadius: "10px",
    border: "1",
    background: "#fff",
    marginTop: "5px",
    minHeight: "0",
    height: "33px",
    width: "100px",
  }),
};

const LoadHolder = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
`;
