import React, { ChangeEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { css } from "@emotion/react";
import MoonLoader from "react-spinners/MoonLoader";
import ReCAPTCHA from "react-google-recaptcha";
import { Article, ArticleRequest, CartView, IpInfo } from "../../dto/Dtos";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getCartItems,
  getPriceFromCountry,
  getPriceFromCountryAsText,
  removeFromCart,
} from "./CartActions";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  fetchArticlesById,
  fetchIpInfo,
  fetchShippingPrice,
  postItemRequest,
} from "../../controller/ShopController";
import Confirmation from "./Confirmation";

const { REACT_APP_API_RECAPTCHA_KEY } = process.env;

const override = css`
  display: block;
  position: relative;
  border-color: #313131;
`;

export enum SwimLane {
  Summary,
  Adress,
  Shipping,
  Confirmation,
}

enum Parcel {
  Free = "free",
  Shipping = "shipping",
}

enum Countries {
  Sweden = "Sweden",
  Finland = "Finland",
  Norway = "Norway",
  Denmark = "Denmark",
}

const ShoppingCart = () => {
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Article[]>([]);
  const [shipping, setShipping] = useState<Article>();
  const [response, setResponse] = useState<Response>();
  const [view, setView] = useState<CartView>(CartView.Cart);
  const [ipInfo, setIpInfo] = useState<IpInfo>();
  const [laneState, setlaneState] = useState<SwimLane>(SwimLane.Summary);
  const [parcel, setParcel] = useState(false);

  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<ArticleRequest>({ mode: "onChange" });

  const resetSubmit = () => {
    setLoading(false);
    reset(
      {
        name: "",
        mail: "",
        address: "",
        postcode: "",
        locality: "",
        country: "",
        telephoneNumber: "",
        message: "",
      },
      {
        keepValues: false,
      }
    );
  };

  const onSubmit = (data: ArticleRequest) => {
    postItemRequest(
      data,
      recaptchaToken ? recaptchaToken : "",
      selectedItems.map((article) => article.id)
    ).then((response) => {
      setResponse(response);
      resetSubmit();
    });
  };

  const handleShippingChange = async (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value as Parcel;

    if (value === Parcel.Free) {
      setParcel(false);
      const newSelectedItems = selectedItems.slice(0, -1);
      setSelectedItems(newSelectedItems);
    } else {
      if (shipping) {
        setParcel(true);
        const newSelectedItems = [...selectedItems, shipping];
        setSelectedItems(newSelectedItems);
      }
    }
  };

  const handleCountryChange = (value: string) => {
    for (const country in Countries) {
      if (typeof Countries[country as keyof typeof Countries] === "string") {
        if (value === country && ipInfo) {
          var newIpInfo = { country_name: country };
          setIpInfo({
            ...ipInfo,
            ...newIpInfo,
          });
        }
      }
    }
  };

  function getCountryOptions(): React.JSX.Element[] {
    const elementArray: React.JSX.Element[] = [];
    for (const country in Countries) {
      if (typeof Countries[country as keyof typeof Countries] === "string") {
        elementArray.push(<option value={country}>{country}</option>);
      }
    }
    return elementArray;
  }

  function isHidden(lane: SwimLane): boolean {
    if (laneState === lane) {
      return false;
    }
    return true;
  }

  const handleRecaptchaChange = (token: string | null) => {
    setRecaptchaToken(token);
  };

  const addCallback = (data: ArticleRequest) => {
    if (laneState === SwimLane.Confirmation) {
      setLoading(true);
      onSubmit(data);
      setView(CartView.Confirmation);
      return;
    } else if (laneState === SwimLane.Adress) {
      setlaneState(SwimLane.Shipping);
    } else if (laneState === SwimLane.Shipping) {
      setlaneState(SwimLane.Confirmation);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const fetchCart = () =>
    fetchArticlesById(getCartItems()).then((articles) => {
      setSelectedItems(articles);
    });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const getIpInfo = async () => {
      const ipInfo = await fetchIpInfo();
      setIpInfo(ipInfo);
    };

    const fetchShipping = () =>
      fetchShippingPrice(getCartItems()).then((article) => {
        setShipping(article);
      });
    getIpInfo();
    fetchCart();
    fetchShipping();
  }, []);

  function changeView(): React.JSX.Element {
    if (view === CartView.Cart) {
      return (
        <RequestWrapper>
          <LoadHolder>
            <MoonLoader
              color="#000000"
              loading={loading}
              css={override}
              size={50}
            />
          </LoadHolder>
          <MainContent>
            <Form onSubmit={handleSubmit(addCallback)}>
              <Title>Shopping cart</Title>
              <TitleBar done={laneState !== SwimLane.Summary}>
                1. Summary
              </TitleBar>
              <BillingContainer
                hidden={
                  isHidden(SwimLane.Summary) && isHidden(SwimLane.Confirmation)
                }
                style={{ width: "100%" }}
              >
                <Table style={{ width: "100%", marginBottom: "20px" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Product</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell style={{ width: "50px" }}>Remove</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedItems.map((article, index) => (
                      <TableRow>
                        <TableCell>{article.name}</TableCell>
                        <TableCell>
                          {ipInfo
                            ? getPriceFromCountryAsText(
                                article,
                                ipInfo.country_name
                              )
                            : ""}
                        </TableCell>
                        <TableCell>
                          <div
                            onClick={() => {
                              if (laneState === SwimLane.Summary) {
                                removeFromCart(index);
                                fetchCart();
                              }
                            }}
                          >
                            <DeleteIcon
                              style={{
                                color: "#f70707",
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow style={{ background: "lightyellow" }}>
                      <TableCell>Total</TableCell>
                      <TableCell>
                        {ipInfo
                          ? selectedItems
                              .map(
                                (item) =>
                                  getPriceFromCountry(item, ipInfo.country_name)
                                    .price
                              )
                              .reduce(function (accumulator, currentValue) {
                                return accumulator + currentValue;
                              }, 0)
                          : ""}{" "}
                        {selectedItems[0] && ipInfo
                          ? getPriceFromCountry(
                              selectedItems[0],
                              ipInfo.country_name
                            ).currency
                          : ""}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                <SummaryButton
                  style={
                    laneState === SwimLane.Summary ? {} : { display: "none" }
                  }
                  disabled={selectedItems.length === 0}
                  enabled={selectedItems.length > 0}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setlaneState(SwimLane.Adress);
                    clearErrors();
                    window.scrollTo({ top: 200, behavior: "smooth" });
                  }}
                >
                  Next step
                </SummaryButton>
              </BillingContainer>
              <TitleBar
                done={
                  laneState !== SwimLane.Adress &&
                  laneState !== SwimLane.Summary
                }
              >
                2. BILLING INFORMATION
              </TitleBar>
              <BillingContainer hidden={isHidden(SwimLane.Adress)}>
                <Input
                  type="text"
                  width={"40%"}
                  placeholder="Name"
                  {...register("name", { required: true, maxLength: 80 })}
                />
                {errors.name?.type === "required" && (
                  <ErrorMessage>First name is required</ErrorMessage>
                )}

                <Input
                  type="email"
                  placeholder="Mail"
                  width={"58%"}
                  {...register("mail", { required: true, maxLength: 100 })}
                />
                {errors.mail && <ErrorMessage>Mail is required</ErrorMessage>}
                <Input
                  width={"25%"}
                  type="telephoneNumber"
                  placeholder="Telephone"
                  {...register("telephoneNumber", {
                    required: true,
                    maxLength: 100,
                  })}
                />
                {errors.telephoneNumber && (
                  <ErrorMessage>Telephone is required</ErrorMessage>
                )}
                <Input
                  type="address"
                  width={"73%"}
                  placeholder="Street address & number"
                  {...register("address", { required: true, maxLength: 100 })}
                />
                {errors.address && (
                  <ErrorMessage>Address is required</ErrorMessage>
                )}
                <Input
                  type="postcode"
                  width={"32%"}
                  placeholder="Postcode/Postnummer"
                  {...register("postcode", { required: true, maxLength: 100 })}
                />
                {errors.postcode && (
                  <ErrorMessage>Postcode is required</ErrorMessage>
                )}
                <Input
                  type="locality"
                  width={"32%"}
                  placeholder="Locality/Lokalitet/Ort"
                  {...register("locality", { required: true, maxLength: 100 })}
                />
                {errors.locality && (
                  <ErrorMessage>Locality is required</ErrorMessage>
                )}
                <Select
                  width={"32%"}
                  {...register("country", { required: true, maxLength: 100 })}
                  onChange={(e) => handleCountryChange(e.target.value)}
                >
                  {getCountryOptions()}
                </Select>
                {errors.country && (
                  <ErrorMessage>Country is required</ErrorMessage>
                )}

                <InputText
                  {...register("message", {})}
                  placeholder="Message (Optional)"
                />
                <InputButton value={"Next step"} />
              </BillingContainer>
              <TitleBar
                done={
                  laneState !== SwimLane.Shipping &&
                  laneState !== SwimLane.Adress &&
                  laneState !== SwimLane.Summary
                }
              >
                3. SHIPPING INFORMATION
              </TitleBar>
              <BillingContainer hidden={isHidden(SwimLane.Shipping)}>
                <RadioWrapper>
                  <RadioContainer>
                    <RadioButton
                      id="inStore"
                      name="inStore"
                      value={Parcel.Free}
                      checked={!parcel}
                      onChange={handleShippingChange}
                    />
                    <label htmlFor="inStore">Pick up at store for free</label>
                  </RadioContainer>
                  <RadioContainer>
                    <RadioButton
                      id="shipping"
                      name="shipping"
                      value={Parcel.Shipping}
                      onChange={handleShippingChange}
                      checked={parcel}
                    />
                    <label htmlFor="shipping">
                      Shipping via Parcel (POSTNORD, BRING, DHL){" "}
                      {shipping && ipInfo
                        ? getPriceFromCountryAsText(
                            shipping,
                            ipInfo.country_name
                          )
                        : ""}
                    </label>
                  </RadioContainer>
                </RadioWrapper>
                <InputButton value={"Next step"} />
              </BillingContainer>
              <TitleBar
                done={
                  laneState !== SwimLane.Shipping &&
                  laneState !== SwimLane.Adress &&
                  laneState !== SwimLane.Confirmation &&
                  laneState !== SwimLane.Summary
                }
              >
                4. CONFIRMATION
              </TitleBar>
              <BillingContainer
                style={{ width: "100%" }}
                hidden={isHidden(SwimLane.Confirmation)}
              >
                <ReCAPTCHA
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                  sitekey={REACT_APP_API_RECAPTCHA_KEY as string}
                  onChange={handleRecaptchaChange}
                />
                <InputButton
                  type="submit"
                  value={"Purchase"}
                  style={{ width: "300px" }}
                />
              </BillingContainer>
            </Form>
          </MainContent>
        </RequestWrapper>
      );
    } else {
      return (
        <Confirmation
          cartItems={selectedItems}
          response={response}
          setView={setView}
          setLoading={setLoading}
          setlaneState={setlaneState}
        />
      );
    }
  }

  return <ShopContainer>{changeView()}</ShopContainer>;
};

export default ShoppingCart;

const ShopContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 10px;
  height: 90vh;

  background: var(--shop-bg-color);
  @media screen and (max-width: 1100px) {
    margin-top: 80px;
    width: 100%;
    height: 100%;
  }
`;

const LoadHolder = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
`;

const RequestWrapper = styled.div`
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  color: #000;
  display: flex;
  z-index: 100;
  height: 100%;
  width: 100%;

  border-radius: 10px;

  @media screen and (max-width: 1100px) {
    display: grid;
  }
`;

const MainContent = styled.div`
  height: 100%;
  width: 70%;
  margin-bottom: 10%;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  line-height: 1.8;
  color: #141414;

  @media screen and (max-height: 980px) {
    grid-template-columns: 1fr;
    padding: 15px;
    width: 100%;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 1rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  justify-content: center;
`;

interface BillingContainerProps {
  hidden: boolean;
}
const BillingContainer = styled.div<BillingContainerProps>`
  max-height: ${({ hidden }) => (hidden ? "0" : "700px")};
  transition: max-height 0.15s ease-out;
  overflow: hidden;
  padding: ${({ hidden }) => (hidden ? "0" : "5px")};
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  justify-content: center;
  -webkit-justify-content: center;
`;

interface TitleBarProps {
  done: boolean;
}

const TitleBar = styled.div<TitleBarProps>`
  width: 100%;
  color: white;
  background: ${({ done }) => (done ? "#0c862a" : "#000")};
  margin-bottom: 10px;
`;

interface InputProps {
  width: string;
}
const Input = styled.input<InputProps>`
  background: #fff;
  color: #000;
  width: ${({ width }) => (width ? width : "100%")};
  border-radius: 10px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  height: 35px;
  margin-bottom: 10px;
  border-color: transparent;
  text-align: center;
  &:active {
    background-color: #8b8b8b;
  }
  @media screen and (max-width: 450px) and (max-height: 950px) {
    width: 100%;
  }

  @media screen and (max-width: 400px) and (max-height: 700px) {
    height: 30px;
    font-size: 10px;
  }
`;

const Select = styled.select<InputProps>`
  background: #fff;
  color: #000;
  width: ${({ width }) => (width ? width : "100%")};
  border-radius: 10px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  height: 35px;
  margin-bottom: 10px;
  border-color: transparent;
  text-align: center;
  &:active {
    background-color: #8b8b8b;
  }
  @media screen and (max-width: 400px) and (max-height: 700px) {
    font-size: 10px;
    height: 20px;
  }
`;

const ErrorMessage = styled.span`
  color: red;
`;

const InputText = styled.textarea`
  background: #fff;
  color: #000;
  width: 100%;
  height: 80px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  margin-bottom: 10px;
  border-color: transparent;

  &:active {
    background-color: #8b8b8b;
  }

  @media screen and (max-width: 400px) and (max-height: 700px) {
    height: 50px;
    font-size: 10px;
  }
`;

const RadioWrapper = styled.div`
  display: grid;
  padding: 0 50px;
  grid-template-columns: 1fr 1fr;
  margin: 20px 0;

  @media screen and (max-width: 450px) and (max-height: 950px) {
    padding: 0;
  }
`;

const RadioContainer = styled.div`
  display: flex;
`;

const RadioButton = styled.input.attrs({ type: "radio" })`
  height: 20px;
  width: 20px;
  margin-right: 5px;
`;

const InputButton = styled.input.attrs({ type: "submit" })`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  width: 20%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 24px;
  margin-bottom: 20px;
  background: #141414;
  color: #fff;
  border: none;
  border-radius: 0;
  &:active {
    background-color: #8b8b8b;
  }

  @media screen and (max-width: 400px) and (max-height: 700px) {
    font-size: 12px;
    height: 30px;
  }
`;

interface SummaryButtonProps {
  enabled: boolean;
}
const SummaryButton = styled.button<SummaryButtonProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 24px;
  margin-bottom: 20px;
  background: ${({ enabled }) => (enabled ? "#141414" : "#818181")};
  color: #fff;
  border: none;
  border-radius: 0;
  font-size: 14px;

  &:active {
    background-color: #8b8b8b;
  }

  @media screen and (max-width: 800px) {
    font-size: 12px;
    height: 40px;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 50px;
  @media screen and (max-width: 1100px) {
    font-size: 2rem;
  }
`;
