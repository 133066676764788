export class UnAuthorizedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "UnAuthorizedError";
  }
}

export class InternalError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "InternalError";
  }
}
