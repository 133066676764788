import React from "react";
import styled from "styled-components";
import SearchBar from "./Searchbar";

interface ShopNavProps {
  setSearchText: (text: string) => void;
}

const ShopNav: React.FC<ShopNavProps> = ({ setSearchText }) => {
  return (
    <Nav>
      <NavbarContainer>
        <ContentRow1>
          <ColumnOne1>
            <SearchBar setSearchText={setSearchText} />
          </ColumnOne1>
        </ContentRow1>
      </NavbarContainer>
    </Nav>
  );
};

export default ShopNav;

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
`;

const NavbarContainer = styled.div`
  z-index: 1;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const ContentRow1 = styled.div`
  display: grid;

  @media screen and (max-width: 1100px) {
    grid-auto-columns: minmax(auto, 1fr) minmax(auto, 2fr) minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: "col1 col2 col3";
    grid-template-areas: "col2" "col1";
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const ColumnOne1 = styled.div`
  padding: 0 15px;
  margin-bottom: 15px;
  grid-area: col1;
  display: flex;
`;
