import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import ContactSection from "../components/ContactSection/Contact";
import { paragraph1 } from "../components/ContactSection/Data";
import { navbarItems } from "../NavbarContent";

const ContactPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} navbarItems={navbarItems} />
      <Navbar toggle={toggle} setScroll={false} navbarItems={navbarItems} />
      <ContactSection paragraph1={paragraph1} />
    </>
  );
};

export default ContactPage;
