import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import ArticleTable from "./ArticleTable";

const AdminMain = () => {
  let navigate = useNavigate();
  const [showArchived, setShowArchived] = useState<boolean>(false);

  return (
    <>
      <TillbakaContainer>
        <Button onClick={() => window.history.back()} dim={false}>
          Tillbaka
        </Button>
      </TillbakaContainer>

      <InputArea>
        <Button onClick={() => navigate("/admin/products")} dim={true}>
          Hantera Produkter
        </Button>
        <Button onClick={() => navigate("/admin/products")} dim={true}>
          Visa statistik
        </Button>
        <CheckBox
          checked={showArchived}
          onClick={() => setShowArchived(!showArchived)}
        />
        Visa endast arkiverad
      </InputArea>

      <Page>
        <ArticleTable showArchived={showArchived} />
      </Page>
    </>
  );
};

export default AdminMain;

const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding-left: 200px;
  padding-right: 200px;
  align-items: center;
  justify-content: center;
  border-color: black;
  text-align: center;
  border: 5px;

  @media screen and (max-width: 1280px) {
    grid-template-columns: 1fr 1fr;
    padding: 0;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
`;

const TillbakaContainer = styled.div`
  width: 100%;
  padding-left: 10%;
`;

const InputArea = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const Button = styled.button`
  display: inline;
  margin-top: 6px;
  margin-left: 10px;
  height: 35px;
  padding: 10px 24px;
  color: #fff;
  border: none;
  background: ${({ dim }) => (dim ? "#141414" : "#818181")};
`;

const CheckBox = styled.input.attrs({ type: "checkbox" })`
  display: inline;
  margin: 0 10px;
  height: 20px;
  width: 20px;
  padding: 10px 24px;
  color: #fff;
  border: none;
`;
