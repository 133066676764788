export interface NavbarItem {
  text: string;
  url: string;
}

export const navbarItems: NavbarItem[] = [
  { text: "Home", url: "/" },
  { text: "Buy", url: "/shop" },
  { text: "Downloads", url: "/downloads" },
  { text: "Support", url: "/support" },
  { text: "Contact", url: "/contact" },
];
