import styled from "styled-components";

export const ContentContainer = styled.div`
  color: #fff;
  margin-top: 200px;
  width: 100vw;

  background: ${({ primaryBg }) =>
    primaryBg ? "var(--main-bg-color)" : "var(--secondary-bg-color)"};

  @media screen and (max-width: 768px) {
    padding: 100px 0;
    margin-top: 0px;
  }
`;

export const ContentWrapper = styled.div`
  z-index: 1;
  width: 100%;
  height: fit-content;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
  @media screen and (max-width: 768px) {
    margin-bottom: 50px;
  }
`;

export const ContentRow = styled.div`
  margin-bottom: 100px;
  width: 100%;
  align-items: center;
`;

export const TextWrapper = styled.div`
  width: 100%;
  align-items: center;
  text-align: center;
  padding-top: 0;
`;

export const Heading = styled.h1`
  margin-bottom: 14px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) =>
    lightText ? "var(--main-text-color)" : "var(--secondary-text-color)"};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) =>
    darkText ? "var(--secondary-text-color)" : "var(--main-text-color)"};
`;

export const TextArea = styled.p`
  width: 100%;
  margin-bottom: 35px;
  font-size: 15px;
  color: ${({ darkText }) =>
    darkText ? "var(--secondary-text-color)" : "var(--main-text-color)"};
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

export const ImgWrap = styled.div`
  width: 100%;
  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;

export const Img = styled.img`
  width: 800px;
  margin: 0 0 10px 0;
  padding-right: 0;
  @media screen and (max-width: 768px) {
    height: 120px;
    width: 100%;
  }
`;

export const ImgSmall = styled.img`
  width: 200px;
  margin: 0 0 10px 0;
  padding-right: 0;
  @media screen and (max-width: 768px) {
    height: 200px;
    width: 200px;
  }
`;

export const SecondTitle = styled.div`
  color: black;
  margin-bottom: 20px;
  font-size: 2rem;
  width: 100%;
  text-align: center;
`;

export const SupportGrid = styled.div`
  color: black;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  justify-content: center;

  @media screen and (max-width: 1300px) {
    grid-gap: 8px;
    padding: 0 20px;
    min-height: 0;
  }
  @media screen and (max-width: 1300px) {
    grid-template-columns: 1fr;
  }
`;

export const SupportColumn = styled.div`
  font-size: 15px;

  @media screen and (max-width: 768px) {
    margin-top: 15px;
    width: 100%;
  }
`;
