import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Article, IpInfo, ShopView } from "../../dto/Dtos";
import { addToCart, getPriceFromCountryAsText } from "./CartActions";
import { useNavigate } from "react-router-dom";

const { REACT_APP_API_HOST } = process.env;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

interface ItempreviewProps {
  selectedItem: Article | undefined;
  ipInfo: IpInfo | undefined;
  setView: React.Dispatch<React.SetStateAction<ShopView>>;
}

const Itempreview: React.FC<ItempreviewProps> = ({
  selectedItem,
  ipInfo,
  setView,
}) => {
  let navigate = useNavigate();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.scrollTo({ top: 0, behavior: "smooth" });

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ItemWrapper>
      <LoadHolder></LoadHolder>
      <ItemContent>
        <StyledImage
          src={
            REACT_APP_API_HOST +
            (windowDimensions.width < 1000
              ? "/api/v2/article/image/high/"
              : "/api/v2/article/image/original/") +
            selectedItem?.imagePath
          }
          alt="test"
        />
        <h3>{selectedItem?.name}</h3>
        <p>{selectedItem?.description}</p>
        <ItemPrice>
          {selectedItem && ipInfo
            ? getPriceFromCountryAsText(selectedItem, ipInfo.country_name)
            : ""}
        </ItemPrice>
        <SpecArea>
          <Spec>{selectedItem?.text1}</Spec>
          <Spec>{selectedItem?.text2}</Spec>
          <Spec>{selectedItem?.text3}</Spec>
          <Spec>{selectedItem?.text4}</Spec>
        </SpecArea>
        <div>
          <RequestButton
            soldOut={selectedItem?.inStock === 0}
            onClick={() => {
              if (selectedItem && selectedItem.inStock !== 0) {
                addToCart(selectedItem.id);
              }
              navigate("/cart");
            }}
          >
            Order now
          </RequestButton>
          <RequestButton
            soldOut={selectedItem?.inStock === 0}
            onClick={() => {
              if (selectedItem && selectedItem.inStock !== 0) {
                addToCart(selectedItem.id);
              }
            }}
          >
            Add to cart
          </RequestButton>
          <RequestButton
            soldOut={selectedItem?.inStock === 0}
            onClick={() => {
              setView(ShopView.Main);
            }}
          >
            Go back
          </RequestButton>
        </div>
      </ItemContent>
    </ItemWrapper>
  );
};

export default Itempreview;

interface RequestButtonProps {
  soldOut: boolean;
}

const RequestButton = styled.button<RequestButtonProps>`
  margin-top: 5%;
  padding: 10px 24px;
  color: #fff;
  border-radius: 15px;
  margin-left: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  border: none;
  background: ${({ soldOut }) =>
    soldOut ? "#8b8b8b" : "var(--button-primary-bgcolor)"};
  :hover {
    background: ${({ soldOut }) => (soldOut ? "#8b8b8b" : "#5f5f5f")};
  }
  :active {
    background: ${({ soldOut }) => (soldOut ? "#8b8b8b" : "#24b13b")};
  }
`;

const ItemWrapper = styled.div`
  margin-top: 200px;
  display: flex;
  width: 100%;
  justify-content: center;
  color: #000;
  padding-bottom: 100px;

  h1 {
    margin-bottom: -5px;
  }

  @media screen and (max-width: 768px) {
    margin-left: 5%;
    margin-right: 5%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    width: 90%;
  }
`;

const ItemContent = styled.div`
  display: flex;
  width: 500px;
  border-radius: 10px;
  background: #fff;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  line-height: 1.8;
  color: #141414;
  padding: 5px;
`;

const StyledImage = styled.img`
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
`;

const LoadHolder = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
`;

const SpecArea = styled.div`
  margin-top: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
`;

const Spec = styled.div`
  padding-top: 5px;
  display: block;
  line-height: 0.9rem;
  font-size: 0.8rem;
`;

const ItemPrice = styled.h4`
  display: block;
  margin-top: 10px;
  font-size: 1.2rem;
  text-align: center;
  font-family: "Poppins", sans-serif;
  @media screen and (max-width: 480px) {
    font-size: 0.8rem;
  }
`;
