import React, { useEffect, useState } from "react";
import { Slide } from "react-slideshow-image";
import { useNavigate } from "react-router-dom";

import "react-slideshow-image/dist/styles.css";
import {
  ContentContainer,
  ContentWrapper,
  ContentRow,
  Column1,
  Column2,
  TextWrapper,
  BottomLine,
  Heading,
  Subtitle,
  ImgWrap,
  Img,
  TextArea,
  FeatureWrapper,
  FeatureCard,
  FeatureTitleWrapper,
  FeatureTitle,
  FeatureImg,
  FeatureImgWrapper,
  FeatureTitleImgWrapper,
  FeatureText,
  BtnWrap2,
  ShopButton,
  ImgBtn,
} from "./ContentElements";
import { Article, IpInfo } from "../../dto/Dtos";
import { fetchArticles, fetchIpInfo } from "../../controller/ShopController";
import styled from "styled-components";
import { getPriceFromCountryAsText } from "../ShopSection/CartActions";

const ContentSection = ({ paragraph1, paragraph2, features }) => {
  let navigate = useNavigate();
  const [articles, setArticles] = useState<Article[]>([]);
  const [ipInfo, setIpInfo] = useState<IpInfo>();

  const { REACT_APP_API_HOST } = process.env;

  useEffect(() => {
    fetchArticles(0, "").then((wrapper) => {
      setArticles(wrapper.articles);
    });
    window.scrollTo({ top: 0, behavior: "smooth" });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getIpInfo = async () => {
      const ipInfo = await fetchIpInfo();
      setIpInfo(ipInfo);
    };
    getIpInfo();
  }, []);

  return (
    <>
      <ContentContainer lightBg={paragraph1.lightBg} id={paragraph1.id}>
        <ContentWrapper>
          <ContentRow imgStart={paragraph1.imgStart}>
            <Column1>
              <ImgWrap>
                <Slide>
                  {paragraph1.images.map((img, index) => (
                    <Img src={img} alt={paragraph1.alt} key={index} />
                  ))}
                </Slide>
              </ImgWrap>
            </Column1>
            <Column2>
              <TextWrapper>
                <Heading lightText={paragraph1.lightText}>
                  {paragraph1.headline}
                </Heading>
                <BottomLine>{paragraph1.bottomLine}</BottomLine>
                <Subtitle darkText={paragraph1.darkText}>
                  {paragraph1.description}
                </Subtitle>
                <TextArea darkText={paragraph1.darkText}>
                  {paragraph1.description2}
                </TextArea>
              </TextWrapper>
              <ShopWrapper>
                {articles
                  .filter((article) => !article.hide && article.priority === 0)
                  .map((article: Article) => (
                    <ShopCard
                      key={article.id}
                      onClick={(e) => navigate("/shop/" + article.id)}
                    >
                      {article.inStock === 0 ? (
                        <SoldOut>Sold out</SoldOut>
                      ) : null}
                      <ItemImage
                        src={
                          REACT_APP_API_HOST +
                          "/api/v2/article/image/med/" +
                          article.imagePath
                        }
                      ></ItemImage>
                      <ItemDescription>{article.name}</ItemDescription>
                      <ItemPrice>
                        {ipInfo
                          ? getPriceFromCountryAsText(
                              article,
                              ipInfo.country_name
                            )
                          : "0 KR"}
                      </ItemPrice>
                      <ShopButton>Buy now</ShopButton>
                    </ShopCard>
                  ))}
              </ShopWrapper>
            </Column2>
          </ContentRow>
        </ContentWrapper>
        <ContentWrapper>
          <ContentRow imgStart={paragraph2.imgStart}>
            <Column1></Column1>
            <Column2>
              <TextWrapper>
                <BottomLine>{paragraph2.bottomLine}</BottomLine>
                <Subtitle darkText={paragraph2.darkText}>
                  {paragraph2.description}
                </Subtitle>
                <TextArea darkText={paragraph2.darkText}>
                  {paragraph2.description2}
                </TextArea>
              </TextWrapper>
              <ImgWrap>
                <ImgBtn src={paragraph2.img} alt={paragraph2.alt} />
              </ImgWrap>
            </Column2>
          </ContentRow>
        </ContentWrapper>
        <FeatureWrapper>
          {features.map((feature, index: number) => (
            <FeatureCard key={index}>
              <FeatureTitleImgWrapper>
                <FeatureImgWrapper>
                  <FeatureImg src={feature.img}></FeatureImg>
                </FeatureImgWrapper>
                <FeatureTitleWrapper>
                  <FeatureTitle>{feature.title}</FeatureTitle>
                </FeatureTitleWrapper>
              </FeatureTitleImgWrapper>
              <FeatureText>{feature.text}</FeatureText>
            </FeatureCard>
          ))}
        </FeatureWrapper>
        <BtnWrap2>
          <ShopButton
            onClick={() => {
              navigate("/shop");
            }}
          >
            {paragraph1.buttonLabel}
          </ShopButton>
        </BtnWrap2>
      </ContentContainer>
    </>
  );
};

export default ContentSection;

const ShopWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 64px;

  @media screen and (max-width: 1300px) {
    grid-gap: 8px;
  }

  @media screen and (max-width: 768px) {
    grid-gap: 8px;
    padding: 0 20px;
    grid-template-columns: 1fr;
  }
`;

const ShopCard = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
  border-radius: 10px;
  width: 350px;
  height: 500px;
  align-items: center;
  text-align: center;
  padding: 20px 10px 20px 10px;
  margin-bottom: 20px;
  transition: all 0.2s ease-in-out;

  &:hover {
    @media screen and (min-width: 1300px) {
      transform: scale(1.02);
      transition: all 0.2s ease-in-out;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1300px) {
    width: 250px;
    height: 450px;
    justify-self: center;
  }

  @media screen and (max-width: 768px) {
    width: auto;
    height: auto;
  }
`;

const ItemDescription = styled.h3`
  display: block;
  color: black;
  font-size: 1rem;
  font-weight: 900;
  margin-bottom: 5px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  @media screen and (max-width: 480px) {
    font-size: 1.1rem;
  }
`;

const SoldOut = styled.div`
  color: red;
  padding: 20px;
  font-weight: 800;
  background-color: #00000063;
  display: flex;
  position: absolute;
`;

const ItemPrice = styled.h4`
  display: block;
  font-size: 1rem;
  color: black;
  font-weight: 900;
  text-align: center;
  font-family: "Poppins", sans-serif;
  @media screen and (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const ItemImage = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  margin-bottom: 20px;
`;
