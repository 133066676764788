import {
  Article,
  ArticleRequest,
  ArticleWrapper,
  Currency,
  IpInfo,
} from "../dto/Dtos";

const { REACT_APP_API_HOST } = process.env;

export async function fetchArticle(id: number): Promise<Article> {
  const res = await fetch(REACT_APP_API_HOST + "/api/v2/article/" + id);
  const data = (await res.json()) as Article;
  return data;
}

export async function fetchArticles(
  page: number,
  searchText: string
): Promise<ArticleWrapper> {
  var string = REACT_APP_API_HOST + "/api/v2/article?page=" + page;
  if (searchText !== undefined && searchText !== null && searchText !== "") {
    string = string.concat("&search=" + searchText);
  }

  const res = await fetch(string);
  const data = await res.json();
  return data;
}

export async function fetchCurrency(): Promise<Currency> {
  const ipJson = await fetch("https://ipapi.co/json");
  const ip = await ipJson.json();
  const res = await fetch(
    REACT_APP_API_HOST + "/api/v2/article/currency/" + ip.ip
  );
  const data = await res.json();
  return data;
}

export async function fetchArticlesById(ids: number[]): Promise<Article[]> {
  const res = await fetch(
    REACT_APP_API_HOST + "/api/v2/article/ids?ids=" + ids.join(","),
    {
      method: "GET",
    }
  );
  const data = await res.json();
  return data;
}

export async function fetchIpInfo(): Promise<IpInfo> {
  const ipJson = await fetch("https://ipapi.co/json");
  const ip = (await ipJson.json()) as IpInfo;
  return ip;
}

export async function postItemRequest(
  articleRequest: ArticleRequest,
  recaptchaToken: string,
  cartItems: number[]
) {
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("Accept", "application/json");
  requestHeaders.set("Content-Type", "application/json");
  requestHeaders.set("Recaptcha-Token", recaptchaToken);
  articleRequest.articleIds = cartItems;
  return fetch(REACT_APP_API_HOST + "/api/v2/article/request", {
    method: "POST",
    headers: requestHeaders,
    body: JSON.stringify(articleRequest),
  });
}

export async function fetchShippingPrice(ids: number[]): Promise<Article> {
  const res = await fetch(
    REACT_APP_API_HOST + "/api/v2/article/shipping?ids=" + ids.join(","),
    {
      method: "GET",
    }
  );
  const data = await res.json();
  return data;
}
