const { REACT_APP_API_HOST } = process.env;

export async function downloadFile(fileName: string): Promise<void> {
  const fileUrl = REACT_APP_API_HOST + "/download/" + fileName;
  const link = document.createElement("a");
  link.href = fileUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
