import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { ArticleRequestDTO } from "../../dto/Dtos";
import MoonLoader from "react-spinners/MoonLoader";

import {
  fetchRequest,
  openedRequest,
  sendMessage,
  sendParcelInfo,
} from "../../controller/AdminController";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  getPriceFromCountry,
  getPriceFromCountryAsText,
} from "../ShopSection/CartActions";
import { InternalError, UnAuthorizedError } from "../../dto/CustomErrors";

const Requests = () => {
  let navigate = useNavigate();
  let { request_id } = useParams();
  const [articleRequest, setArticleRequest] = useState<ArticleRequestDTO>();
  const [ready, setReady] = useState<boolean>();
  const [replyMessage, setReplyMessage] = useState<string>("");
  const [parcel, setParcel] = useState<string>("");
  const [loading, setLoading] = useState(false);

  if (request_id) {
    openedRequest(parseInt(request_id));
  }

  const handleProcessDoneClick = async (id: number, parcel: string) => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    setLoading(true);
    await sendParcelInfo(id, parcel);
    setLoading(false);
    navigate("/admin");
  };

  const handleSendMessageClick = async (id: number, message: string) => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    setLoading(true);
    await sendMessage({ id: id, message: message });
    setLoading(false);
  };

  const override = css`
    display: block;
    position: relative;
    border-color: #313131;
  `;

  useEffect(() => {
    // Fetch data from the server
    const fetchData = async () => {
      if (request_id) {
        try {
          const data = await fetchRequest(parseInt(request_id));
          if (data && data !== null) {
            setArticleRequest(data);
          }
        } catch (e) {
          if (e instanceof UnAuthorizedError) {
            navigate("/login");
          } else if (e instanceof InternalError) {
            window.alert(e.message);
          }
        }
      }
    };
    fetchData();
  }, [request_id, navigate]);

  return (
    <>
      <LoadHolder>
        <MoonLoader
          color="#000000"
          loading={loading}
          css={override}
          size={50}
        />
      </LoadHolder>
      <InputArea>
        <Button onClick={() => window.history.back()} black={false}>
          Tillbaka
        </Button>
        <Button onClick={() => navigate("/admin/products")} black={true}>
          Hantera Produkter
        </Button>
        <Button onClick={() => navigate("/admin/products")} black={true}>
          Visa statistik
        </Button>
      </InputArea>

      <Page>
        {articleRequest ? (
          <RequestContainer>
            <Container white={false}>
              <Table
                style={{
                  width: "100%",
                  display: "block",
                  overflowX: "hidden",
                  overflowY: "scroll",
                  height: "350px",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell
                      style={{
                        width: "90%",
                      }}
                    >
                      Product
                    </TableCell>
                    <TableCell>Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {articleRequest.articles.map((article) => (
                    <TableRow key={article.id}>
                      <TableCell>
                        <a href={"/shop/" + article.id}>{article.id}</a>
                      </TableCell>
                      <TableCell>
                        <a href={"/shop/" + article.id}>{article.name}</a>
                      </TableCell>
                      <TableCell>
                        {getPriceFromCountryAsText(
                          article,
                          articleRequest.country
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow style={{ background: "lightyellow" }}>
                    <TableCell></TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>
                      {articleRequest.articles
                        .map(
                          (item) =>
                            getPriceFromCountry(item, articleRequest.country)
                              .price
                        )
                        .reduce(function (accumulator, currentValue) {
                          return accumulator + currentValue;
                        }, 0)}
                      {articleRequest.articles[0]
                        ? getPriceFromCountry(
                            articleRequest.articles[0],
                            articleRequest.country
                          ).currency
                        : ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Container white={true} style={{ marginTop: "20px" }}>
                <Text>Name: {articleRequest.name}</Text>
                <Text>
                  Adress:{" "}
                  <TextField style={{ minHeight: "50px" }}>
                    {articleRequest.address}
                    <br />
                    {articleRequest.postcode + " " + articleRequest.locality}
                    <br />
                    {articleRequest.country}
                  </TextField>
                </Text>
                <Text>Telephone: {articleRequest.telephoneNumber}</Text>
                <Text>Mail: {articleRequest.mail}</Text>
                <Text>
                  Message: <TextField>{articleRequest.message}</TextField>
                </Text>
                <Text>
                  &nbsp; IpAddress & Location:
                  {articleRequest.ipAddress + " : " + articleRequest.location}
                </Text>
                <Text>
                  Date:&nbsp;
                  {new Date(articleRequest.date).toLocaleDateString() +
                    " " +
                    new Date(articleRequest.date).toLocaleTimeString()}
                </Text>
              </Container>
            </Container>
            <Container white={false}>
              <InputText
                placeholder="Svara tillbaka"
                onKeyUp={(e) => {
                  setReplyMessage(e.currentTarget.value);
                }}
              ></InputText>
              <Button
                disabled={false}
                onClick={() =>
                  handleSendMessageClick(articleRequest.id, replyMessage)
                }
                black={true}
              >
                Svara
              </Button>
              <Input
                width="100%"
                placeholder="Lägg till paket/trackingnummer"
                defaultValue={
                  articleRequest.parcelNumber ? articleRequest.parcelNumber : ""
                }
                onKeyUp={(e) => {
                  setReady(e.currentTarget.value !== "");
                  setParcel(e.currentTarget.value);
                }}
              ></Input>

              <Button
                disabled={!ready}
                onClick={() =>
                  handleProcessDoneClick(articleRequest.id, parcel)
                }
                black={ready}
              >
                Markera som klar
              </Button>
            </Container>
          </RequestContainer>
        ) : null}
      </Page>
    </>
  );
};

export default Requests;

const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding-left: 200px;
  padding-right: 200px;
  align-items: center;
  justify-content: center;
  border-color: black;
  text-align: center;
  border: 5px;

  @media screen and (max-width: 1280px) {
    grid-template-columns: 1fr 1fr;
    padding: 0;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
`;

const RequestContainer = styled.div`
  background-color: #f6f7f8;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2%;
  border-radius: 20px;
  padding: 50px;
  width: 80%;
  @media screen and (max-width: 768px) {
    width: 100%;
    grid-template-columns: 1fr;
  }
`;

const InputArea = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const Button = styled.button`
  display: inline;
  margin-top: 6px;
  margin-left: 10px;
  height: 35px;
  padding: 10px 24px;
  color: #fff;
  border: none;
  background: ${({ black }) => (black ? "#141414" : "#818181")};
`;

interface ContainerProps {
  white: boolean;
}
const Container = styled.div<ContainerProps>`
  width: 100%;
  border-radius: ${({ white }) => (white ? "20px" : "0")};
  padding: ${({ white }) => (white ? "20px" : "0")};

  background: ${({ white }) => (white ? "#ffffff" : "#f6f7f8")};
`;

const Text = styled.div`
  width: 100%;
  font-size: 16px;
  margin-bottom: 10px;
  display: block;
  text-align: left;
`;

const TextField = styled.div`
  width: 100%;
  margin-bottom: 10px;
  min-height: 200px;
  display: block;
  padding: 10px;
  text-align: left;
  background-color: #d5d8d8;
`;

const InputText = styled.textarea`
  background: #fff;
  color: #000;
  width: 100%;
  height: 300px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  margin-bottom: 10px;
  border-color: transparent;

  &:active {
    background-color: #8b8b8b;
  }
`;

interface InputProps {
  width: string;
}
const Input = styled.input<InputProps>`
  background: #fff;
  color: #000;
  text-transform: uppercase;
  width: ${({ width }) => (width ? width : "100%")};
  border-radius: 10px;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  height: 45px;
  margin-top: 100px;
  margin-bottom: 15px;
  border-color: transparent;
  text-align: center;
  &:active {
    background-color: #8b8b8b;
  }
  @media screen and (max-width: 450px) and (max-height: 950px) {
    width: 100%;
  }

  @media screen and (max-width: 400px) and (max-height: 700px) {
    height: 30px;
    font-size: 10px;
  }
`;

const LoadHolder = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
`;
