import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";

import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinkr,
} from "./NavbarElements";
import { NavbarItem } from "../../NavbarContent";

interface NavbarProps {
  toggle: any; // Assuming Person is an interface or type that you have defined
  setScroll: any;
  navbarItems: NavbarItem[];
}

const Navbar: React.FC<NavbarProps> = ({ toggle, setScroll, navbarItems }) => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const [scrollNav, setScrollNav] = useState(!setScroll);

  const changeNav = React.useCallback(() => {
    if (!setScroll) {
      setScrollNav(true);
      return;
    }
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  }, [setScroll]);

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
    return () => {
      window.removeEventListener("scroll", changeNav);
    };
  }, [changeNav]);

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to="/" onClick={toggleHome}>
            Chitram TV NORDIC
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            {navbarItems.map((item, index: number) => (
              <NavItem key={index}>
                <NavLinkr to={item.url}>{item.text}</NavLinkr>
              </NavItem>
            ))}
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
