import React, { useEffect, useState } from "react";
import { MdOutlineShoppingCart } from "react-icons/md";
import styled from "styled-components";
import { getCartItems } from "../ShopSection/CartActions";
import { useNavigate } from "react-router-dom";

const ShoppingCartIcon = () => {
  const [itemsInCart, setItemsInCart] = useState(getCartItems());
  const [isBlinking, setIsBlinking] = useState(false);
  const [width, setWidth] = useState<number>(window.innerWidth);

  const navigate = useNavigate();

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    const handleStorageChange = () => {
      setItemsInCart(getCartItems());
      setIsBlinking(true);
      setTimeout(() => setIsBlinking(false), 1500);
    };
    window.addEventListener("itemUpdated", handleStorageChange);
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("itemUpdated", handleStorageChange);
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const handleWhatsAppClick = async () => {
    navigate("/cart");
  };

  return (
    <Container
      onClick={handleWhatsAppClick}
      style={
        isBlinking
          ? {
              animation: "stretch 1.5s ease-in-out 1",
            }
          : {}
      }
    >
      <Number>{itemsInCart.length}</Number>

      <MdOutlineShoppingCart
        style={isMobile ? { fontSize: "46px" } : { fontSize: "60px" }}
        color="#000000"
      />
      <ContactUS>Cart</ContactUS>
    </Container>
  );
};

export default ShoppingCartIcon;

const Container = styled.div`
  position: fixed;
  padding: 10px;
  bottom: 130px;
  right: 20px;
  cursor: pointer;

  @keyframes stretch {
    0% {
      transform-origin: right;
      transform: scale(2, 1.5);
    }

    100% {
      transform: 0;
    }
  }

  @media screen and (max-width: 768px) {
    bottom: 100px;
    right: 0px;
    padding: 0px;

    @keyframes stretch {
      0% {
        transform: scale(1.5, 1.5);
      }

      100% {
        transform: 0;
      }
    }
  }
`;

const ContactUS = styled.div`
  display: block;
  font-weight: 600;
  width: 80px;
  margin-bottom: 5px;
`;

const Number = styled.div`
  display: block;
  position: fixed;
  font-weight: 900;
  padding: 2px;
  width: 25px;
  height: 25px;
  background-color: #ff000073;
  border-radius: 50%;
  transform: translate(250%, -50%);

  @media screen and (max-width: 768px) {
    font-weight: 600;
    font-size: 13px;
    width: 20px;
    height: 20px;
    transform: translate(280%, -50%);
  }
`;
